@import "_box-sizing.module.scss";
@import "_screen-readers.module.scss";

@mixin checkbox {
  align-items: center;
  display: flex;
  margin: $spacer * 0.25 0;
  position: relative;
  top: 0;
  @include boxSizingAll;
  @include forMdUp {
    margin: 0;
  }
  &:hover {
    top: -1px;
  }
}

@mixin check {
  @include srOnly;
  &:checked {
    + .checkLabel {
      &:before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
      &:after {
        content: "";
        display: block;
        border-color: $white;
        border-style: solid;
        border-width: 0 $spacer * 0.125 $spacer * 0.125 0;
        width: $spacer * 0.375;
        height: $spacer * 0.75;
        position: absolute;
        top: 1px;
        left: $spacer * 0.3125;
        transform: rotate(45deg);
        margin: 0;
      }
    }
  }
  &[disabled] {
    + .checkLabel {
      cursor: not-allowed;
      &:before {
        background-color: $grayLighter;
        border: $borderBase $gray;
      }
    }
  }
}

@mixin checkLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightBold;
  line-height: 1;
  position: relative;
  margin: $spacer * 0.125 0 0;
  @include forMdUp {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightMedium;
  }
  &:before {
    content: "";
    background-color: $white;
    border: $borderBase $gray;
    display: block;
    margin: 0 $spacer * 0.375 0 0;
    min-width: $spacer;
    min-height: $spacer;
    width: $spacer;
    height: $spacer;
  }
  &:hover {
    &:before {
      background-color: $grayLightest;
    }
  }
  &.checkLabelSrOnly {
    &:before {
      margin-right: 0;
    }
  }
}
