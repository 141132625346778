@mixin tfoot {
  background-color: $grayLightest;
  border: $borderBase $grayLight;
  border-top: 0;

  p {
    font-size: $fontSizeSmall;
    margin: 0;
  }
}
