// Font families
$fontFamilyHtml: sans-serif;
$fontFamilyBase: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto",
  "Helvetica Neue", sans-serif;
$fontFamilyBody: $fontFamilyBase;

// Sizes
$fontSizeRoot: $basePx;
$fontSizeBase: 1rem;
$fontSizeBody: $fontSizeBase;
$fontSizeSmallest: 0.625em;
$fontSizeSmaller: 0.75em;
$fontSizeSmall: 0.875em;
$fontSizeMedium: $fontSizeBase;
$fontSizeLarge: 1.125em;
$fontSizeLarger: 1.25em;
$fontSizeLargest: 1.375em;

// Weights
$fontWeightBase: 400;
$fontWeightBody: $fontWeightBase;
$fontWeightLightest: 100;
$fontWeightLighter: 200;
$fontWeightLight: 300;
$fontWeightMedium: $fontWeightBase;
$fontWeightBold: 600;
$fontWeightBolder: 700;
$fontWeightBoldest: 800;

// Line height
$lineHeightBase: 1.5;
$lineHeightBody: $lineHeightBase;
$lineHeightSmallest: 1;
$lineHeightSmaller: 1.25;
$lineHeightSmall: 1.375;
$lineHeightMedium: $lineHeightBase;
$lineHeightLarge: 1.75;
$lineHeightLarger: 2;
$lineHeightLargest: 2.25;
