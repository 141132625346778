@mixin alert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  background-color: $grayLightest;
  border: $borderBase $grayLight;
  color: $grayDarker;
  padding: $spacer * 0.875 $spacer $spacer;
  margin: $spacer * 2 0 0;

  &:only-child {
    margin: 0;
  }
}

@mixin alertIcon {
  font-size: $fontSizeMedium * 1.5;
  line-height: $lineHeightSmallest;
  margin-top: $spacer * 0.125;
  margin-right: $spacer * 0.75;

  > span, > i {
    display: block;
  }
}

@mixin alertMessage {
  font-family: $fontFamilyBase;
  font-size: $fontSizeMedium;
  line-height: $lineHeightSmall;
}

@mixin alertSuccess {
  background-color: lighten($successColorLightest, 25%);
  border-color: $successColor;
  color: $successColor;
  a {
    color: $successColor;
    &:visited {
      color: $successColor;
    }
    &:hover,
    &:focus {
      color: $successColorDarker;
    }
    &:active {
      color: $successColor;
    }
  }
}

@mixin alertError {
  background-color: lighten($errorColorLightest, 10%);
  border-color: $errorColor;
  color: $errorColor;
  a {
    color: $errorColor;
    &:visited {
      color: $errorColor;
    }
    &:hover,
    &:focus {
      color: $errorColorDarker;
    }
    &:active {
      color: $errorColor;
    }
  }
}

@mixin alertInfo {
  background-color: lighten($infoColorLightest, 25%);
  border-color: $infoColor;
  color: $infoColor;
  a {
    color: $infoColor;
    &:visited {
      color: $infoColor;
    }
    &:hover,
    &:focus {
      color: $infoColorDarker;
    }
    &:active {
      color: $infoColor;
    }
  }
}

@mixin alertWarning {
  background-color: lighten($warningColorLightest, 25%);
  border-color: $warningColor;
  color: $warningColor;
  a {
    color: $warningColor;
    &:visited {
      color: $warningColor;
    }
    &:hover,
    &:focus {
      color: $warningColorDarker;
    }
    &:active {
      color: $warningColor;
    }
  }
}
