@mixin dropdown {
  display: inline-block;
  position: relative;

  &[aria-expanded="true"] {
    .dropdownList {
      display: block;
    }
  }

  > button {
    > span {
      &:last-child:not(:first-child) {
        margin: 0 0 0 $spacer * 0.25;
        vertical-align: baseline;
      }
    }
  }
}

@mixin dropdownList {
  display: none;
  max-width: $spacer * 20;
  position: absolute;
  z-index: 1;
  padding-bottom: $spacer * 2;

  > button,
  > a {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    &:hover,
    &:focus {
      top: 0;
    }
  }
}
