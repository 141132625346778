@mixin header {
  background-color: darken($grayDarkest, 5%);
  border-bottom: $borderThick $black;
  display: flex;
  align-items: center;
  height: $spacer * 3;
  width: 100%;

  @include forMdUp {
    height: $spacer * 4.5;
  }
  // need for ios devices so the modal doesn't break
  .modalOpen & {
    display: none;
    @include forMdUp {
      display: flex;
    }
  }
}
