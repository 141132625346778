@mixin notFound {
  height: 100%;

  .section {
    height: 100%;
  }

  .sectionInner {
    height: 100%;
    background-color: transparent;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    width: auto;
    max-height: 300px;

    @include forSmUp {
      max-height: 400px;
    }

    @include forMdUp {
      max-height: 500px;
    }
  }

  p {
    font-size: $fontSizeLargest;
    font-weight: $fontWeightLightest;
    margin-top: $spacer;

    @include forXsUp {
      font-size: 1.75em;
    }

    @include forSmUp {
      font-size: 2em;
    }

    a {
      text-decoration: none;
      border-bottom: $borderBase $infoColor;

      &:visited {
        border-bottom-color: $infoColorDarker;
      }

      &:hover,
      &:focus {
        border-bottom-color: $infoColorDarker;
      }

      &:active {
        border-bottom-color: $infoColor;
      }
    }
  }
}
