@mixin screenReadersOnly {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  width: 1px;
}

@mixin srOnly {
  @include screenReadersOnly;
}
