@mixin content {
  flex: 1 1 auto;
  overflow: hidden;
}

@mixin contentInner {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}
