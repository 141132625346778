@mixin boxSizing {
  box-sizing: border-box;
}

@mixin boxSizingAll {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}
