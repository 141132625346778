@mixin select {
  display: block;
  width: 100%;
  height: $spacer * 1.875;
  box-sizing: border-box;
  padding: $spacer * 0.25 $spacer * 1.75 $spacer * 0.25 $spacer * 0.5;
  background-color: $white;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAPCAYAAADzun+cAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo2ZmU1ZDIzMS00MTMzLTgwNGYtOWI4NC0zZmM5YmQ3MTc3M2YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjgzNzM4NUExODkyMTFFOEExOENCNDVDRjQ5OTQ0OEYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjgzNzM4NTkxODkyMTFFOEExOENCNDVDRjQ5OTQ0OEYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjE4MGEzMDNiLTc1YmQtOWE0YS04ZTUwLWFmYTRhMDI5MWIwMyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ZmU1ZDIzMS00MTMzLTgwNGYtOWI4NC0zZmM5YmQ3MTc3M2YiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6axaaTAAAA/UlEQVR42mJgGGCQCcSTgZiZhnYwQ+3IhAlIAPFXIP4PxNuAmJcGlvJCzQbZ8QWIxWESlkD8EipxGYgVqWgpyKxLULNfQu3CUHAFnwIyALKHruDzED8Q74Qq/A7EERRYGgE14z/UTH5CGliAeCpUwz8grgNiRhIsBKmther9D01QLKS4OAeI/0A1LwdiDiL0gNQsg+r5AzWDLOAJxJ+gBh0DYjE8asWgav5D9XhSmkB0gfgh1MB7QKyNRY02VA6k5j5UD1UAsm8+oPnGHSpGTKiQBTigcY0cf+SkA7IAKMU2IaVYWMpvIjHlkw1igPgHNJ9G07tisaSkdAMIMAAZwEcu893qVAAAAABJRU5ErkJggg==");
  background-position: right $spacer * 0.5 top 50%;
  background-repeat: no-repeat;
  background-size: $spacer * 0.875;
  border: $borderBase $gray;
  border-radius: 0;
  color: $black;
  appearance: none;
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
  line-height: $lineHeightSmall;
  margin: 0;

  &:hover {
    border-color: $grayDarkest;
  }

  &:focus {
    border-color: $infoColor;
    outline: 0;
  }

  &[disabled] {
    background-color: $grayLightest;
    border-color: $grayLight;
    color: $grayDark;
    cursor: not-allowed;

    &:hover {
      border-color: $gray;
    }
  }

  &::-ms-expand {
    display: none;
  }
}

@mixin selectRequired {
  @include select;
  background-color: $errorColorLightest;
  border-color: $errorColor;
  &:hover {
    border-color: $grayDarkest;
  }
  &:focus {
    outline: 0;
    border-color: $infoColor;
  }
  &[disabled] {
    background-color: $grayLightest;
    border-color: $gray;
  }
}

@mixin selectWrapper {
  display: block;
  position: relative;
}

@mixin selectWrapperRequired {
  display: block;
  position: relative;
  padding-right: $spacer * 0.5;
}

@mixin selectLarge {
  @include select;
  font-size: $fontSizeMedium;
  height: $spacer * 2;
  padding: $spacer * 0.25 $spacer * 2 $spacer * 0.25 $spacer * 0.5;
  background-size: $spacer;
}

@mixin selectLarger {
  @include select;
  font-size: $fontSizeLarger;
  height: $spacer * 2.375;
  padding: $spacer * 0.25 $spacer * 2.125 $spacer * 0.25 $spacer * 0.5;
  background-size: $spacer * 1.125;
}

@mixin selectLargest {
  @include select;
  font-size: $fontSizeLargest;
  font-weight: $fontWeightLight;
  height: $spacer * 2.75;
  padding: $spacer * 0.25 $spacer * 2.25 $spacer * 0.375 $spacer * 0.5;
  background-size: $spacer * 1.375;

  option {
    font-weight: $fontWeightLight;
  }
}
