@mixin required {
    &:after {
        color: $errorColor;
        content: "*";
        display: block;
        margin: 0;
        position: absolute;
        right: 0;
        top: $spacer * -0.125;
        font-size: $fontSizeMedium;
        line-height: 1;
      }
}