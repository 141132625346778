@mixin label {
  display: block;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightBold;
  line-height: $lineHeightSmaller;
  padding: 0 0 $spacer * 0.25; // use padding and not margin for label hover
  margin: 0;

  .fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .required {
    &:after {
      display: inline-block;
      position: static;
      vertical-align: top;
    }
  }
}
