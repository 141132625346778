@mixin body {
  background-color: $white;
  color: $grayDarkest;
  font-family: $fontFamilyBase;
  font-size: $fontSizeBody;
  font-weight: $fontWeightBody;
  line-height: $lineHeightBody;
  margin: 0;
  overflow: hidden;

  &.loginLogoutReset {
    overflow: auto;
  }
}
