// border width
$borderWidthBase: 1px;
$borderWidthThick: 2px;
$borderWidthThicker: 3px;

// border style
$borderStyleBase: solid;
$borderStyleDashed: dashed;

// border-radius
$borderRadius: 5px;

// borders
$borderBase: $borderWidthBase $borderStyleBase;
$borderThick: $borderWidthThick $borderStyleBase;
$borderThicker: $borderWidthThicker $borderStyleBase;
$borderDashed: $borderWidthThick $borderStyleDashed;
