@mixin nav {
  background-color: lighten($grayDarkest, 5%);
  border-left: $borderThick $grayDarkest;
  width: $spacer * 6;
  height: calc(100vh - #{$spacer * 3}); // closeMenu height

  @include forMdUp {
    border-left: 0;
    border-right: $borderThick $black;
    height: calc(100vh - #{$spacer * 4});
    position: relative;
    width: $spacer * 5;
    z-index: 3;
  }

  .navsOpen & {
    border-right: $borderThick $black;
  }
}

@mixin navInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: $spacer * 0.5;

  @include forMdUp {
    padding-right: 0;
  }
}

@mixin navBottom {
  margin-top: auto;
}

@mixin navLink {
  color: $grayLight;
  display: block;
  font-weight: 400;
  left: 0;
  line-height: $lineHeightSmaller;
  padding: $spacer * 0.75 $spacer * 0.25;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:visited {
    color: $grayLight;
  }

  &:hover,
  &:focus {
    color: $grayLightest;
    background-color: lighten($grayDarkest, 10%);
    text-decoration: none;
  }

  &:active {
    color: $white;
  }
}

@mixin navLinkActive {
  cursor: default;
  color: $grayLightest;
  background-color: lighten($grayDarkest, 10%);
}

@mixin navLinkText {
  display: block;
  font-size: $fontSizeSmaller;
  line-height: $lineHeightSmall;
}

@mixin navLinkIcon {
  display: block;
  font-size: $fontSizeMedium * 2;
  line-height: $lineHeightSmallest;
  margin-bottom: $spacer * 0.325;

  i {
    display: block;
  }

  [data-icon-name="Color"] {
    font-size: $fontSizeMedium * 1.5;
  }

  [data-icon-name="SpeedHigh"] {
    font-size: $fontSizeMedium * 1.625;
  }
}

@mixin navLinkBorder {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: $gray;
  width: $borderWidthThick;

  .navLinkActive & {
    display: block;
  }
}
