@mixin thead {
  display: none;
  background-color: $primaryColor;
  border: $borderBase $primaryColor;
  color: $white;
  text-align: left;
  
  @include forMdUp {
    display: table-header-group;
  }

  > tr {
    background-color: transparent;
    color: $white;

    &:hover {
      background-color: transparent;
    }
  }
}
