// base
$black: #000;
$white: #fff;

// grays
$gray: #aaa;
$grayLightest: #f4f4f4;
$grayLighter: #eaeaea;
$grayLight: #ccc;
$grayDark: #777;
$grayDarker: #555;
$grayDarkest: #333;

// brand colors
// primary color
// coke red (actually SharePoint red theme, but close enough)
$primaryColor: #e51e2b;
$primaryColorLightest: lighten($primaryColor, 30%);
$primaryColorLighter: lighten($primaryColor, 20%);
$primaryColorLight: lighten($primaryColor, 10%);
$primaryColorDark: #a70d17;
$primaryColorDarker: darken($primaryColorDark, 10%);
$primaryColorDarkest: darken($primaryColorDark, 20%);
$primaryColorInverse: $white;
// secondary color
// mellow yellow
$secondaryColor: #ffdd00;
$secondaryColorInverse: $primaryColor;
// tertiary color
// coke life green
$tertiaryColor: #587f48;
$tertiaryColorInverse: $white;
// quaternary color
// sprite blue
$quaternaryColor: #005ba4;
$quaternaryColorInverse: $white;

// state colors
// warning
$warningColor: #ff9900;
$warningColorInverse: $white;
$warningColorLightest: lighten($warningColor, 30%);
$warningColorLighter: lighten($warningColor, 20%);
$warningColorLight: lighten($warningColor, 10%);
$warningColorDark: darken($warningColor, 10%);
$warningColorDarker: darken($warningColor, 20%);
$warningColorDarkest: darken($warningColor, 30%);
// error
$errorColor: #d0021b;
$errorColorInverse: $white;
$errorColorLightest: lighten($errorColor, 45%);
$errorColorLighter: lighten($errorColor, 35%);
$errorColorLight: lighten($errorColor, 20%);
$errorColorDark: darken($errorColor, 10%);
$errorColorDarker: darken($errorColor, 20%);
$errorColorDarkest: darken($errorColor, 30%);
// info
$infoColor: #348eda;
$infoColorInverse: $white;
$infoColorLightest: lighten($infoColor, 30%);
$infoColorLighter: lighten($infoColor, 20%);
$infoColorLight: lighten($infoColor, 10%);
$infoColorDark: darken($infoColor, 7.5%);
$infoColorDarker: darken($infoColor, 15%);
$infoColorDarkest: darken($infoColor, 22.5%);
// success
$successColor: #68b90f;
$successColorInverse: $white;
$successColorLightest: lighten($successColor, 30%);
$successColorLighter: lighten($successColor, 20%);
$successColorLight: lighten($successColor, 10%);
$successColorDark: darken($successColor, 10%);
$successColorDarker: darken($successColor, 20%);
$successColorDarkest: darken($successColor, 30%);
// save
$saveColor: #587f48;
$saveColorInverse: $white;
$saveColorLightest: lighten($saveColor, 30%);
$saveColorLighter: lighten($saveColor, 20%);
$saveColorLight: lighten($saveColor, 10%);
$saveColorDark: darken($saveColor, 10%);
$saveColorDarker: darken($saveColor, 20%);
$saveColorDarkest: darken($saveColor, 30%);
