@mixin linkColor($color, $visited, $hover, $active) {
  color: $color;
  &:visited {
    color: $visited;
  }
  &:hover,
  &:focus {
    color: $hover;
  }
  &:active {
    color: $active;
  }
}

@mixin link {
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  @include linkColor($infoColor, $infoColorDarker, $infoColorDarker, $infoColor);
}

@mixin linkDisabled {
  @include link;
  pointer-events: none;
  opacity: 0.4;
}
