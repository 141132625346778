@mixin trBorder {
  border-top: $borderBase $grayLightest;
  &:first-child {
    border-top: 0;
  }
}

@mixin tr {
  display: block;
  @include trBorder;
  @include forMdUp {
    display: table-row;
  }
}

@mixin trHover {
  @include tr;
  &:hover {
    background-color: $grayLightest;
  }
}

@mixin trStriped {
  @include tr;
  &:nth-child(even) {
    background-color: lighten($grayLightest, 2%);
  }
}

@mixin trStripedHover {
  @include tr;
  @include trStriped;
  @include trHover;
}

@mixin trSuccess {
  @include tr;
  position: relative;
  background-color: lighten($successColorLightest, 25%);
  border-left: 3px solid $successColor;
  &:hover {
    background-color: lighten($successColorLightest, 20%);
  }
}

@mixin trError {
  @include tr;
  position: relative;
  background-color: lighten($errorColorLightest, 11%);
  border-left: 3px solid $errorColor;
  &:hover {
    background-color: lighten($errorColorLightest, 6%);
  }
}

@mixin trWarning {
  @include tr;
  position: relative;
  background-color: lighten($warningColorLightest, 15%);
  border-left: 3px solid $warningColor;
  &:hover {
    background-color: lighten($warningColorLightest, 12%);
  }
}

@mixin trInfo {
  @include tr;
  position: relative;
  background-color: lighten($infoColorLightest, 13%);
  border-left: 3px solid $infoColor;
  &:hover {
    background-color: lighten($infoColorLightest, 10%);
  }
}

@mixin notMatched {
  background-color: lighten($errorColorLightest, 6%);
}

@mixin trNotMatched {
  @include tr;
  @include notMatched;
}

@mixin checked {
  background-color: lighten($infoColorLightest, 10%);
}

@mixin trChecked {
  @include tr;
  @include checked;
}

