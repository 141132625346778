@mixin payrollPeriods {
  max-width: 900px;

  .buttonWhite {
    .buttonIcon {
      @include forMdUp {
        margin-right: 0;
      }
      @include forLgUp {
        margin-right: $spacer * 0.25;
      }
    }
    .buttonText {
      @include forMdUp {
        display: none;
      }
      @include forLgUp {
        display: inline-block;
      }
    }
  }
}
