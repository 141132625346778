@mixin flex {
  display: flex;
}

@mixin flexAlignCenter {
  @include flex;
  align-items: center;
}

$gutter: $spacer;

@mixin grid {
  padding-left: $gutter;
  padding-right: $gutter;
}

@mixin row {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-left: -($gutter);
  margin-right: -($gutter);
  width: auto;

  &:not(:first-child) {
    margin-top: 0;
  }
}

@mixin column {
  margin-top: $spacer;
  max-width: 100%;
  min-height: 1px; // IE11 fix
  padding-left: $gutter;
  padding-right: $gutter;
  width: 100%;

  .row:first-child & {
    &:first-child {
      margin-top: 0;
    }
  }
}

@mixin columnAuto {
  margin-top: 0;
  margin-bottom: $spacer;
  width: auto;
}
