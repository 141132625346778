@mixin navs {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  height: 100vh;
  width: $spacer * 6;
  transition: width 0.2s ease-in-out;

  @include forMdUp {
    display: block;
    height: calc(100vh - #{$spacer * 4});
    position: relative;
    width: $spacer * 5;
    z-index: auto;
  }

  .menuOpen & {
    display: block;
    animation-name: openMenu;
    animation-delay: 0.1s;
    animation-duration: 0.3s;
    animation-fill-mode: both;

    @include forMdUp {
      animation: none;
    }
  }

  .menuIsClosing & {
    display: block;
    animation-name: closeMenu;
    animation-duration: 0.3s;
    animation-fill-mode: both;

    @include forMdUp {
      animation: none;
    }
  }
}

@mixin navsOpen {
  width: $spacer * 16;

  @include forMdUp {
    width: $spacer * 15;
  }
}

@mixin navsInner {
  display: flex;
  width: 100%;
  height: calc(100vh - #{$spacer * 3}); // closeMenu height
  position: relative;
}

@keyframes openMenu {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

@keyframes closeMenu {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}
