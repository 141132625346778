@mixin hr {
  display: block;
  width: 100%;
  background-color: $grayLight;
  box-sizing: content-box;
  margin: $spacer * 0.75 0;
  border: 0;
  overflow: visible;
  height: $borderWidthBase;

  @include forMdUp {
    margin: $spacer * 1.25 0;
  }
}
