@mixin h6 {
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightBolder;
  line-height: $lineHeightMedium;
  margin: $spacer 0;
  text-transform: uppercase;
  @include forMdUp {
    margin: $spacer * 2 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:only-child {
    margin: 0;
  }

  .required {
    &:after {
      display: inline-block;
      position: static;
      vertical-align: baseline;
    }
  }
}
