@mixin kanbanBoard {
  margin-bottom: 0;
  border-top: $borderBase $grayLight;
  border-bottom: $borderBase $grayLight;
  background-color: $grayLightest;
  min-height: $spacer * 10;
  .col {
    width: 25%;
    border-right: $borderBase $grayLight;
    &:first-child {
      border-left: none;
      .kanbanHeading {
        border-left: $borderBase $grayLight;
      }
    }
    // hide the last border in the kanban board
    // its set to "2" because there is another child at the end of the kanban class
    &:nth-last-child(2) {
      border-right: none;
      .kanbanHeading {
        border-right: $borderBase $grayLight;
      }
    }
  }
}

@mixin kanbanHeading {
  border-bottom: $borderBase $grayLight;
  padding: $spacer - 0.5 $spacer * 1.5;
  box-shadow: inset 0px -1px 1px -1px $gray;
  background-color: $white;
}

@mixin kanbanColumn {
  padding: $spacer $spacer * 1.5;
  height: 100%;
  .kanbanCard:last-child {
    margin-bottom: 0;
  }
}

@mixin kanbanCard {
  width: 100%;
  border-radius: $borderRadius;
  background-color: $white;
  box-shadow: 1px 1px 7px $gray;
  margin-bottom: $spacer;
}

@mixin kanbanCardTitle {
  padding: $spacer - 0.5 $spacer;
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  text-transform: uppercase;
  font-size: $fontSizeSmaller;
  display: flex;
  align-items: center;
}

@mixin kanbanCardBody {
  padding: $spacer - 0.5 $spacer;
}
