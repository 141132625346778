@mixin modalWrapper {
  display: block;
}

@mixin modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9; // Needs to be 9 or larger to overlay SharePoint things
  background-color: rgba($black, 0.75);
  margin: 0;
  padding: $spacer;

  &[aria-hidden="false"] {
    display: flex;
  }

  @media (min-width: 1024px) {
    padding: $spacer * 2;
  }
}

@mixin modalIsOpening {
  @include modal;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-fill-mode: both;

  .modalInner {
    animation-name: fadeInDown;
    animation-duration: 0.4s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
  }
}

@mixin modalIsClosing {
  @include modal;
  animation-name: fadeOut;
  animation-duration: 0.2s;
  animation-fill-mode: both;

  .modalInner {
    animation-name: fadeOutUp;
    animation-duration: 0.4s;
    animation-fill-mode: both;
  }
}

@mixin modalInner {
  display: block;
  width: 100%;
  margin: 0;
  background-color: $white;
}

@mixin modalHeader {
  display: flex;
  align-items: center;
  padding: $spacer * 0.5 $spacer;
  border-bottom: $borderBase $grayLight;
  background-color: $grayLightest;

  > button,
  .button {
    margin-top: 0;
    margin-left: auto;

    .buttonIconLeft {
      margin-right: 0;

      @include forXsUp {
        margin-right: $spacer * 0.25;
      }
    }

    .buttonText {
      display: none;

      @include forXsUp {
        display: inline-block;
      }
    }
  }

  > h6 {
    font-size: 1.375rem;
    line-height: $lineHeightSmaller;
    font-weight: $fontWeightBold;
    margin: 0;
    text-transform: none;
  }
}

$modalBodyPadding: $spacer;

@mixin modalBody {
  display: block;
  position: relative; // for loader
  max-height: 50vh;
  min-height: $spacer * 13;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-height: 400px) {
    max-height: 40vh;
  }

  @media (min-height: 500px) {
    max-height: 50vh;
  }

  @media (min-height: 600px) {
    max-height: 70vh;
  }

  @media (min-height: 1200px) {
    max-height: 80vh;
  }

  // p {
  //   margin: $spacer * 0.25 0 $spacer * 0.5;
  //   &:first-child {
  //     margin-top: 0;
  //   }
  // }
}

@mixin modalBodyPadding {
  padding: $modalBodyPadding;
}

@mixin modalFooter {
  padding: $spacer * 0.75 $spacer;
  border-top: $borderBase $grayLight;
  background-color: $grayLightest;

  @media (min-width: 500px) {
    display: flex;
    align-items: center;
  }

  > div,
  .alert {
    margin-top: 0;
    margin-right: $spacer;
    padding: $spacer * 0.5;

    > div:first-child,
    .alertIcon {
      font-size: $fontSizeLarge;
      margin-top: 0;

      @media (min-width: 500px) {
        margin-right: $spacer * 0.25;
      }
    }
    > div:last-child,
    .alertMessage {
      font-size: $fontSizeSmall;
      line-height: $lineHeightMedium;
    }
  }

  > button,
  .button {
    margin-top: $spacer;
    margin-right: $spacer * 0.125;

    @media (min-width: 500px) {
      margin-top: 0;
    }

    &:first-of-type {
      margin-left: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .modalBody & {
    margin: $modalBodyPadding * 0.25 0 0;
  }
}
