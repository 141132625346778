@mixin login {
  @include fullscreen;

  .field {
    &:last-child {
      flex-wrap: wrap;
      align-items: center;
      @include forXxxxsUp {
        display: flex;
      }
    }
  }

  button {
    margin-right: $spacer;
  }

  a {
    display: block;
    font-size: $fontSizeSmall;
    margin-top: $spacer;
    @include forXxxxsUp {
      margin-top: 0;
    }
  }
}
