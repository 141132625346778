@mixin subNav {
  background-color: $white;
  width: $spacer * 10;
  position: absolute;
  top: 0;
  right: -($spacer * 10);
  bottom: 0;
  z-index: 3;
  transition: right 0.2s ease-in-out;
  @include forMdUp {
    border-right: $borderThick $grayLighter;
    height: calc(100vh - 4rem);
    left: -($spacer * 10);
    right: auto;
    transition: left 0.2s ease-in-out;
    z-index: 1;
  }
  .navsWide & {
    right: 0;
    @include forMdUp {
      left: $spacer * 5;
      right: auto;
    }
  }
}

@mixin subNavLink {
  align-items: flex-start;
  background-color: $white;
  color: $grayDark;
  display: flex;
  line-height: $lineHeightSmall;
  padding: $spacer * 0.625 $spacer * 0.75;
  position: relative;
  text-decoration: none;
  width: 100%;

  &:visited {
    color: $grayDark;
  }

  &:hover,
  &:focus {
    color: $grayDarkest;
    background-color: $grayLightest;
    text-decoration: none;
  }

  &:active {
    color: $black;
  }
}

@mixin subNavLinkActive {
  pointer-events: all;
  color: $primaryColor;
  &:visited {
    color: $primaryColor;
  }
  &:hover,
  &:focus {
    color: $primaryColor;
    background-color: $white;
  }
  &:active {
    color: $primaryColor;
  }
}

@mixin subNavIcon {
  display: block;
  line-height: $lineHeightSmall;
  i {
    font-size: $fontSizeSmall;
  }
}

@mixin subNavText {
  margin-left: $spacer * 0.375;
}
@mixin subNavSectionHeading {
  color: $grayDark;
  line-height: $lineHeightSmall;
  display: flex;
  align-items: flex-start;
  padding: $spacer $spacer * 0.75 $spacer * 0.25;
  position: relative;
  width: 100%;
  text-transform: uppercase;
  margin: $spacer * 0.5 0 0;
  border-top: $borderBase $grayLight;

  &:first-child {
    border-top: none;
    margin-top: 0;
  }
}
