@mixin cardWrapper {
  position: relative;
  min-height: 1px;
  padding-left: $spacer;
  padding-right: $spacer;
  margin-bottom: $spacer;
}

@mixin cardLink {
  color: $primaryColor;
  display: block;
  position: relative;
  text-decoration: none;
  &:visited {
    color: $primaryColor;
  }
  &:hover {
    background-color: $primaryColor;
    color: $white;
    text-decoration: none;
    top: -1px;
    i {
      color: $white;
    }
    .cardInner {
      border-color: $primaryColorDark;
      border-bottom-color: $primaryColorDarker;
    }
  }
  &:active {
    color: $white;
  }
  i {
    color: $grayDark;
  }
}

@mixin cardInner {
  border: $borderBase $grayLight;
  border-bottom: $borderThick $gray;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@mixin card {
  display: block;
  width: 100%;
  height: 100%;
}

@mixin cardHeader {
  display: block;
  padding: $spacer;
}
@mixin cardBody {
  display: block;
  padding: 0 $spacer;
  &:first-child {
    padding: $spacer $spacer 0;
  }
  &:last-child {
    padding: 0 $spacer $spacer;
  }
  &:only-child {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: $spacer;
  }
}
@mixin cardFooter {
  display: block;
  margin-top: auto;
  padding: $spacer;
}
