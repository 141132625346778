@mixin th {
  font-size: $fontSizeSmaller;
  font-weight: $fontWeightBold;
  line-height: $lineHeightSmaller;
  padding: $spacer * 0.375;
  text-align: left;
  white-space: nowrap;
  @include titles;
  &:last-child {
    border-right: 0;
  }
}
