@mixin fullscreen {
  background-color: $grayDarkest;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacer;
  min-height: 100vh;

  @include forXxsUp {
    padding: $spacer * 2;
  }

  img {
    display: block;
    margin: 0 auto $spacer * 1.5;
    max-width: $spacer * 10;
  }

  label {
    font-size: $fontSizeSmall;
    line-height: $lineHeightSmallest;
    margin: 0 0 $spacer * 0.25;

    .required {
      &:after {
        position: static;
        display: inline-block;
        margin-left: $spacer * 0.125;
      }
    }
  }
}
