@mixin h4 {
  font-family: $fontFamilyBase;
  font-size: $fontSizeLarger;
  font-weight: $fontWeightLighter;
  line-height: $lineHeightSmallest;
  margin: $spacer 0;
  @include forMdUp {
    margin: $spacer * 2 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:only-child {
    margin: 0;
  }
}
