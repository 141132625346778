@mixin td {
  display: block;
  width: 100%;
  padding: $spacer * 0.5 $spacer * 0.5 0;
  position: relative;
  @include titles;

  &:last-child {
    padding: $spacer * 0.5;
  }

  @include forMdUp {
    display: table-cell;
    width: auto;
    padding: $spacer * 0.375;

    &:last-child {
      border-right: 0;
    }
  }
}
