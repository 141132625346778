@import "./box-sizing.module.scss";
@import "./label.module.scss";
@import "./screen-readers.module.scss";

@mixin datePicker {
  display: block;
  width: 100%;
  @include boxSizing;

  div {
    height: auto;
  }

  label {
    @include label;
    @include forMdUp {
      @include srOnly;
    }
  }

  input {
    font-family: $fontFamilyBase;
    font-size: 0.875rem;
    line-height: $lineHeightMedium;
    padding: 0 0.625em 3px;
    height: $spacer * 1.75;
    font-weight: 600;
    &[disabled] {
      background-color: $grayLightest;
      color: $grayDark;
    }
  }

  .is-disabled {
    .ms-TextField-fieldGroup {
      border-color: $grayLight;
    }
    &:hover {
      .ms-TextField-fieldGroup {
        border-color: $gray;
      }
    }
  }

  i {
    top: 6px;
  }
}

@mixin datePickerRequired {
  @include datePicker;
  padding-right: $spacer * 0.25;
}
