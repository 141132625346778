@mixin section {
  padding: 0 $spacer * 0.75 $spacer * 0.5 $spacer * 0.75;
  @include forMdUp {
    padding: 0 $spacer * 1.25 $spacer * 0.5 $spacer * 0.75;
  }
}

@mixin sectionInner {
  background-color: $white;
  border: $borderBase $grayLighter;
  border-bottom: $borderThick darken($grayLighter, 5%);
  min-height: $spacer * 8;
  padding: $spacer * 0.75;
  position: relative;
  @include forMdUp {
    padding: $spacer * 1.25;
  }
}
