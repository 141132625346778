@mixin paragraph {
  margin: 0 0 $spacer;
  white-space: normal;

  &:last-child {
    margin-bottom: 0;
  }

  &:only-child {
    margin-bottom: 0;
  }

  small {
    font-size: 0.875em;
  }
}

@mixin bold {
  font-weight: $fontWeightBold;
}
