@mixin loggedInAs {
  display: inline-block;
  height: 100%;
  position: relative;
  @include forMdUp {
    margin-left: auto;
  }
}

@mixin loggedInAsButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: $fontFamilyBase;
  font-size: $fontSizeLarge;
  font-weight: $fontWeightLighter;
  height: 100%;
  margin: 0;
  padding: 0 $spacer * 0.375;
  position: relative;
  text-align: left;

  @include forMdUp {
    padding: 0 $spacer * 0.75;
  }

  &:hover,
  &:focus {
    background-color: $primaryColorDark;
  }

  &:focus {
    outline: 0;
  }

  [aria-expanded="true"] & {
    background-color: $primaryColorDark;
  }
}

@mixin loggedInAsName {
  display: none;
  @include forMdUp {
    display: block;
  }
}

@mixin loggedInAsPhoto {
  display: block;
  height: $spacer * 2.25;
  width: $spacer * 2.25;
  @include forMdUp {
    height: $spacer * 2.75;
    margin-left: $spacer * 0.625;
    width: $spacer * 2.75;
  }
}

@mixin loggedInAsImage {
  border: 0;
  border-radius: 50%;
  display: block;
  height: auto;
  width: 100%;
}

@mixin loggedInAsPlaceholder {
  align-items: center;
  background-color: $primaryColor;
  border-radius: 50%;
  color: $white;
  display: flex;
  font-size: $fontSizeMedium;
  height: $spacer * 2.25;
  justify-content: center;
  line-height: $lineHeightSmaller;
  padding-bottom: $spacer * 0.125;
  width: $spacer * 2.25;
  text-align: center;

  @include forMdUp {
    font-size: $fontSizeLarge;
    height: $spacer * 2.75;
    width: $spacer * 2.75;
  }
}

@mixin loggedInAsDropdownList {
  background-color: $primaryColorDark;
  box-shadow: 0 $spacer * 0.5 $spacer 0 rgba($black, 0.15);
  display: none;
  position: absolute;
  top: $spacer * 3;
  right: 0;
  width: auto;
  max-width: $spacer * 15;
  z-index: 1;
  @include forMdUp {
    top: $spacer * 4;
    width: 100%;
  }
  [aria-expanded="true"] & {
    display: block;
  }
}

@mixin loggedInAsLink {
  background-color: transparent;
  color: $white;
  display: block;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightLight;
  line-height: $lineHeightMedium;
  padding: $spacer * 0.5 $spacer $spacer * 0.75;
  text-align: right;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  border: 0;
  outline: 0;
  &:visited {
    color: $white;
  }

  &:hover,
  &:focus {
    background-color: $primaryColor;
    color: $white;
    cursor: pointer;
  }

  &:active {
    color: $white;
  }

  i {
    font-size: 0.75em;
    vertical-align: -1px;
  }
}
