@mixin tooltip {
  position: relative;
  &:before,
  &:after {
    display: none;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    position: absolute;
    z-index: 1;
  }
  &:before {
    content: "";
    width: 0;
    height: 0;
  }
  &:after {
    content: attr(data-tip);
    font-size: 0.875em;
    background-color: $black;
    color: $white;
    padding: $spacer * 0.375;
    border-radius: 0;
    width: auto;
    min-width: $spacer * 2;
    text-align: center;
  }
  &:hover,
  &:focus {
    &:before,
    &:after {
      display: block;
    }
  }

  &.tipXxxs {
    &:after {
      min-width: $spacer * 3;
    }
  }

  &.tipXs {
    &:after {
      min-width: $spacer * 4;
    }
  }

  &.tipSm {
    &:after {
      min-width: $spacer * 5;
    }
  }

  &.tipMd {
    &:after {
      min-width: $spacer * 6;
    }
  }

  &.tipLg {
    &:after {
      min-width: $spacer * 7;
    }
  }

  &.tipXl {
    &:after {
      min-width: $spacer * 8;
    }
  }

  &.tipXxl {
    &:after {
      min-width: $spacer * 9;
    }
  }

  &.tipXxxl {
    &:after {
      min-width: $spacer * 10;
    }
  }

  &.tipXxxxl {
    &:after {
      min-width: $spacer * 11;
    }
  }
}

@mixin tooltipTop {
  &:before,
  &:after {
    top: auto;
    right: auto;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &:before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $black;
    border-bottom: 0;
  }
  &:after {
    margin: 0 0 8px;
  }
}

@mixin tooltipRight {
  &:before,
  &:after {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 100%;
    transform: translate(0, -50%);
  }
  &:before {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $black;
    border-left: 0;
  }
  &:after {
    margin: 0 0 0 8px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
