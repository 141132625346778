@mixin foot {
  display: block;
  background-color: $grayLightest;
  border: $borderBase $gray;
  border-top: 0;
  font-size: 0.875em;
  min-height: $spacer * 2;
  padding: $spacer * 0.5;
  p {
    margin: 0;
  }
}
