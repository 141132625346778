@mixin textarea {
  appearance: none;
  background-color: $white;
  border: $borderBase $gray;
  box-shadow: none;
  box-sizing: border-box;
  outline: 0;
  display: block;
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
  line-height: $lineHeightSmall;
  margin: 0;
  max-width: 100%;
  max-height: none;
  min-height: 0;
  min-width: $spacer * 1.875;
  padding: $spacer * 0.25 $spacer * 0.5;
  width: 100%;
  min-height: $spacer * 1.875;
  resize: vertical;
  &:hover {
    border-color: $grayDarkest;
  }
  &:focus {
    outline: 0;
    border-color: $infoColor;
    box-shadow: none;
  }
  &:required {
    outline: 0;
    box-shadow: none;
  }
  &:invalid {
    outline: 0;
    box-shadow: none;
  }
  &::placeholder {
    box-shadow: none;
  }
  &[disabled],
  .fieldset[disabled] & {
    background-color: $grayLightest;
    border-color: $grayLight;
    color: $grayDark;
  }
  &[readonly] {
    background-color: $grayLightest;
    color: $grayDark;
    &:hover {
      border-color: $gray;
    }
  }
  .fieldset[disabled] & {
    cursor: not-allowed;
  }
}

@mixin textareaWrapper {
  display: block;
  position: relative;
  width: 100%;
}

@mixin textareaRequired {
  @include textarea;
  background-color: $errorColorLightest;
  border-color: $errorColor;
  &:hover,
  &:focus {
    border-color: $errorColorDarker;
  }
  &[disabled],
  &[readonly] {
    background-color: $grayLightest;
    border-color: $gray;
    &:hover {
      border-color: $gray;
    }
  }
}

@mixin textareaWrapperRequired {
  @include textareaWrapper;
  padding-right: $spacer * 0.5;
}
