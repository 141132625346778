@mixin input {
  appearance: none;
  background-color: $white;
  border: $borderBase $gray;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  outline: 0;
  display: block;
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
  line-height: $lineHeightSmall;
  margin: 0;
  max-width: 100%;
  max-height: none;
  min-height: 0;
  min-width: $spacer * 1.875;
  padding: $spacer * 0.25 $spacer * 0.5;
  width: 100%;
  height: $spacer * 1.875;

  &:hover {
    border-color: $grayDarkest;
  }

  &:focus {
    outline: 0;
    border-color: $infoColor;
    box-shadow: none;
  }

  &:required {
    outline: 0;
    box-shadow: none;
  }

  &:invalid {
    outline: 0;
    box-shadow: none;
  }

  &::placeholder {
    box-shadow: none;
  }

  &[disabled],
  .fieldset[disabled] & {
    background-color: $grayLightest;
    border-color: $grayLight;
    color: $grayDark;
  }

  &[readonly] {
    background-color: $grayLightest;
    border-color: $grayLight;
    color: $grayDark;

    &:hover {
      border-color: $gray;
    }
  }
  
  .fieldset[disabled] & {
    cursor: not-allowed;
  }
  // hide input[type="search"] X icon
  // and input[type="password"] reveal
  &::-ms-clear,
  &::-ms-reveal,
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
    width: 0;
    height: 0;
  }
}

@mixin inputWrapper {
  display: block;
  position: relative;
  width: 100%;
}

@mixin inputRequired {
  @include input;
  background-color: $errorColorLightest;
  border-color: $errorColor;
  &:hover,
  &:focus {
    border-color: $errorColorDarker;
  }
  &[disabled],
  &[readonly] {
    background-color: $grayLightest;
    border-color: $gray;
    &:hover {
      border-color: $gray;
    }
  }
}

@mixin inputWrapperRequired {
  @include inputWrapper;
  padding-right: $spacer * 0.5;
}

@mixin inputShowPassword {
  padding-right: $spacer * 2;
}

@mixin inputGroupWrapper {
}

@mixin inputGroup {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .inputWrapper {
    width: auto;
  }

  .input {
    @include forSmUp {
      border-right: none;
    }
  }
}

@mixin inputGroupAppend {
  background-color: $grayLight;
  border: $borderBase $gray;
  color: $grayDarkest;
  height: $spacer * 1.875;
  line-height: 1.625;
  font-size: $spacer * 0.75;
  font-weight: $fontWeightMedium;
  margin: 0;
  padding: $spacer * 0.25 $spacer * 0.375;
  white-space: nowrap;
  text-align: center;
}
