@mixin loaderWrapper {
  position: relative;
  min-height: $spacer * 4;
}

@mixin loader {
  font-family: $fontFamilyBase;
  font-size: $fontSizeLarger;
  font-weight: $fontWeightLight;
  padding: $spacer * 0.75;
}

@mixin loaderCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

@mixin loaderTop {
  text-align: center;
  padding-top: $spacer;
}

@mixin loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba($white, 0.8);
  font-size: $fontSizeLargest;
  padding: $spacer * 2;

  .modalBody & {
    padding: $spacer * 0.5;
  }
}

@mixin loaderFullscreen {
  @include fullscreen;
}

@mixin loaderImageWrapper {
  display: block;
  width: $spacer * 6;
  height: $spacer * 6;
  margin: 0 auto $spacer;
  position: relative;
}

@mixin loaderImage {
  position: relative;
}

@mixin loaderCircle {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  margin: 0;
  width: $spacer * 6;
  height: $spacer * 6;
  animation: loader 1s 0s linear infinite;
  background-color: $primaryColor;
  border-radius: 50%;

  &:nth-child(2) {
    animation-delay: -0.4s;
  }

  &:nth-child(3) {
    animation-delay: -0.2s;
  }
}

@mixin hasLoaderImage {
  min-height: $spacer * 8;
}

@mixin loaderText {
  font-size: $fontSizeSmallest;
  font-weight: $fontWeightBolder;
  letter-spacing: 2px;
  line-height: 1.4;
  text-transform: uppercase;

  .hasLoaderImage & {
    margin-left: $spacer * 0.75;
  }

  .loaderFullscreen & {
    color: $white;
    font-size: $fontSizeLarge;
    letter-spacing: 4px;
    line-height: 1;
    margin-left: $spacer * 0.75;
  }
}

@keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@mixin loaderGradientAnimation {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loaderGradient;
  animation-timing-function: linear;
}

@mixin loaderGradient {
  display: block;
  width: 100%;
  height: $spacer * 1.875; // height for inside a table td
  min-width: $spacer * 3;
  background: $grayLightest;
  background: linear-gradient(to right, $grayLightest 10%, $grayLighter 40%, $grayLightest 60%);
  background-size: $spacer * 100 $spacer * 50;
  @include loaderGradientAnimation;
}

@keyframes loaderGradient {
  0% {
    background-position: -($spacer * 100) 0;
  }
  100% {
    background-position: $spacer * 100 0;
  }
}
