@mixin toolbarBase {
  display: flex;
  > * {
    &:last-child {
      margin-top: 0;
      margin-left: auto;
    }
  }
}

@mixin toolbarMargin {
  > * {
    &:last-child {
      margin-top: $spacer;
    }
  }
}

@mixin toolbar {
  @include toolbarBase;
}

@mixin toolbarXxxxs {
  @include toolbarMargin;
  @include forXxxxsUp {
    @include toolbarBase;
  }
}

@mixin toolbarXxxs {
  @include toolbarMargin;
  @include forXxxsUp {
    @include toolbarBase;
  }
}

@mixin toolbarXxs {
  @include toolbarMargin;
  @include forXxsUp {
    @include toolbarBase;
  }
}

@mixin toolbarXs {
  @include toolbarMargin;
  @include forXsUp {
    @include toolbarBase;
  }
}

@mixin toolbarSm {
  @include toolbarMargin;
  @include forSmUp {
    @include toolbarBase;
  }
}

@mixin toolbarMd {
  @include toolbarMargin;
  @include forMdUp {
    @include toolbarBase;
  }
}

@mixin toolbarLg {
  @include toolbarMargin;
  @include forLgUp {
    @include toolbarBase;
  }
}

@mixin toolbarXl {
  @include toolbarMargin;
  @include forXlUp {
    @include toolbarBase;
  }
}

@mixin toolbarXxl {
  @include toolbarMargin;
  @include forXxlUp {
    @include toolbarBase;
  }
}

@mixin toolbarXxxl {
  @include toolbarMargin;
  @include forXxxlUp {
    @include toolbarBase;
  }
}

@mixin toolbarXxxxl {
  @include toolbarMargin;
  @include forXxxxlUp {
    @include toolbarBase;
  }
}
