@mixin pagination {
  display: block;
  font-size: $fontSizeSmall;
}

@mixin paginationList {
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

@mixin paginationListItem {
  display: block;
  margin-right: $spacer * 0.125;
  &:last-child {
    margin-right: 0;
  }
}

@mixin paginationButton {
  @include button;
  background-color: $white;
  border-color: $grayLight;
  color: $grayDark;
  &:hover,
  &:focus {
    background-color: $white;
    color: $primaryColor;
    border-color: $primaryColor;
  }
  &[disabled] {
    background-color: $white;
    border-color: $grayLight;
    color: $gray;
    &:hover,
    &:focus {
      background-color: $white;
      border-color: $grayLight;
      color: $gray;
    }
  }
}

@mixin paginationButtonActive {
  @include button;
  cursor: default;
  pointer-events: none;
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: $white;
  &:hover,
  &:focus {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: $white;
  }
}
