@mixin cards {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin-left: -#{$spacer};
  margin-right: -#{$spacer};
  width: auto;
}

@mixin cardsCentered {
  justify-content: center;
}

@mixin cardsTwoColumns {
  > .cardWrapper {
    flex: 0 0 50%;
  }
}
