@mixin button {
  appearance: none;
  background-color: transparent;
  border: $borderBase transparent;
  color: $grayDarkest;
  cursor: pointer;
  display: inline-block;
  font-family: $fontFamilyBase;
  font-size: 0.875rem;
  font-weight: $fontWeightMedium;
  line-height: $lineHeightSmall;
  margin: 0;
  padding: 0.5em 0.75em;
  width: auto;
  vertical-align: middle;
  position: relative;
  text-decoration: none;

  // override Semantic UI
  &[type="button"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
  }

  &:visited {
    color: $grayDarkest;
  }

  &:hover,
  &:focus {
    top: -1px;
    color: $black;
    text-decoration: none;
  }

  &:active {
    color: $grayDarker;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;

    &:hover,
    &:focus {
      top: 0;
    }
  }
}

@mixin buttonIcon {
  display: inline-block;
  font-size: $fontSizeSmall;
  vertical-align: -1px;

  &:empty {
    display: none;
  }
}

@mixin buttonIconLeft {
  margin-right: $spacer * 0.25;

  &:only-child {
    margin-right: 0;
  }

  .buttonLarge & {
    margin-right: $spacer * 0.375;

    &:only-child {
      margin-right: 0;
    }
  }
}

@mixin buttonIconRight {
  margin-left: $spacer * 0.25;

  &:only-child {
    margin-left: 0;
  }

  .buttonLarge & {
    margin-left: $spacer * 0.375;

    &:only-child {
      margin-left: 0;
    }
  }
}

@mixin buttonText {
  display: inline-block;
  vertical-align: baseline;
}

// sizes
@mixin buttonLarge {
  font-size: $fontSizeLarge;
  line-height: $lineHeightLarge;
  padding: 0.625em 1em;
}

@mixin buttonLarger {
  font-size: $fontSizeLarger;
  line-height: $lineHeightLarger;
  padding: 0.75em 1.25em;
}

@mixin buttonLargest {
  font-size: $fontSizeLargest;
  line-height: $lineHeightLargest;
  padding: 0.875em 1.5em;
}

// colors
@mixin buttonLinkWhite {
  @include button;
  color: $white;

  &:visited {
    color: $white;
  }

  &:hover,
  &:focus {
    color: $grayLightest;
  }

  &:active {
    color: $white;
  }
}

@mixin buttonLinkGray {
  @include button;
  color: $gray;

  &:visited {
    color: $gray;
  }

  &:hover,
  &:focus {
    color: $grayLight;
  }

  &:active {
    color: $grayLighter;
  }
}

@mixin buttonLinkGrayLight {
  @include button;
  color: $grayLight;

  &:visited {
    color: $grayLight;
  }

  &:hover,
  &:focus {
    color: $grayLighter;
  }

  &:active {
    color: $grayLightest;
  }
}

@mixin buttonLinkGrayLighter {
  @include button;
  color: $grayLighter;

  &:visited {
    color: $grayLighter;
  }

  &:hover,
  &:focus {
    color: $grayLightest;
  }

  &:active {
    color: $white;
  }
}

@mixin buttonLinkGrayLightest {
  @include button;
  color: $grayLightest;

  &:visited {
    color: $grayLightest;
  }

  &:hover,
  &:focus {
    color: $white;
  }

  &:active {
    color: $white;
  }
}

@mixin buttonLinkGrayDark {
  @include button;
  color: $grayDark;

  &:visited {
    color: $grayDark;
  }

  &:hover,
  &:focus {
    color: $grayDarkest;
  }

  &:active {
    color: $grayLight;
  }
}

@mixin buttonLinkPrimaryColor {
  @include button;
  color: $primaryColor;
  &:visited {
    color: $primaryColor;
  }

  &:hover,
  &:focus {
    color: $primaryColorDark;
  }

  &:active {
    color: $primaryColor;
  }
}

@mixin buttonLinkSuccessColor {
  @include button;
  color: $successColorDark;
  &:visited {
    color: $successColorDark;
  }

  &:hover,
  &:focus {
    color: $successColorDarker;
  }

  &:active {
    color: $successColorDark;
  }
}

@mixin buttonLinkInfoColor {
  @include button;
  color: $infoColor;
  &:visited {
    color: $infoColor;
  }

  &:hover,
  &:focus {
    color: $infoColorDark;
  }

  &:active {
    color: $infoColor;
  }
}

@mixin buttonWhite {
  @include button;
  background-color: $white;
  border-color: $grayLight;

  &:hover,
  &:focus {
    background-color: $grayLightest;
    border-color: $gray;
  }
}

@mixin buttonCloseModal {
  @include button;
  background-color: $grayLightest;
  border-color: $grayLightest;
  color: $grayDarker;
  font-weight: $fontWeightBolder;
  font-size: $fontSizeLargest * 1.5;
  padding: 0;

  &:hover,
  &:focus {
    background-color: $grayLightest;
    border-color: $grayLightest;
    color: $primaryColor;
    font-weight: $fontWeightBolder;
    font-size: $fontSizeLargest * 1.5;
  }

  &[disabled] {
    background-color: $grayLightest;
    border-color: $grayLightest;
    color: $gray;
    opacity: 1;

    &:hover,
    &:focus {
      background-color: $grayLightest;
      border-color: $grayLightest;
      color: $gray;
    }
  }
}

@mixin buttonPrimary {
  @include button;
  background-color: $primaryColor;
  border-color: $primaryColor;
  color: $white;

  &:visited {
    color: $white;
  }

  &:hover,
  &:focus {
    background-color: $primaryColorDark;
    border-color: $primaryColorDark;
    color: $grayLightest;
  }

  &:active {
    color: $white;
  }

  &[disabled] {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: $white;
  }
}

@mixin buttonInfo {
  @include button;
  background-color: $infoColor;
  border-color: $infoColor;
  color: $white;

  &:visited {
    color: $white;
  }

  &:hover,
  &:focus {
    background-color: $infoColorDark;
    border-color: $infoColorDark;
    color: $grayLightest;
  }

  &:active {
    color: $white;
  }
}

@mixin buttonSuccess {
  @include button;
  background-color: $successColor;
  border-color: $successColor;
  color: $white;

  &:visited {
    color: $white;
  }

  &:hover,
  &:focus {
    background-color: $successColorDark;
    border-color: $successColorDark;
    color: $grayLightest;
  }

  &:active {
    color: $white;
  }
}

@mixin buttonSave {
  @include button;
  background-color: $saveColor;
  border-color: $saveColor;
  color: $saveColorInverse;

  &:visited {
    color: $saveColorInverse;
  }

  &:hover,
  &:focus {
    background-color: $saveColorDark;
    border-color: $saveColorDark;
    color: darken($saveColorInverse, 10%);
  }

  &:active {
    color: $saveColorInverse;
  }
}

@mixin buttonSort {
  @include buttonLinkWhite;
  padding: 0 $spacer * 0.25;
}

@mixin buttonGeneral {
  @include button;
  background-color: $grayLighter;
  border-color: $grayLight;
  color: $grayDarkest;

  &:visited {
    color: $grayDarkest;
  }

  &:hover,
  &:focus {
    background-color: $saveColorDark;
    border-color: $saveColorDark;
    color: darken($saveColorInverse, 10%);
  }

  &:active {
    color: $saveColorInverse;
  }
}

@mixin buttonGeneralSelected {
  @include button;
  background-color: $saveColor;
  border-color: $saveColor;
  color: $saveColorInverse;

  &:visited {
    color: $saveColorInverse;
  }

  &:hover,
  &:focus {
    background-color: $saveColorDark;
    border-color: $saveColorDark;
    color: darken($saveColorInverse, 10%);
  }

  &:active {
    color: $saveColorInverse;
  }
}
