@mixin field {
  margin-top: $spacer;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-top: $spacer * 1.25;
  }

  &:only-child {
    margin-top: 0;
  }

  .legend + & {
    margin-top: 0;
  }
}
