@mixin tableWrapper {
  margin: $spacer 0;
  overflow-x: auto;
  position: relative;

  @include forMdUp {
    margin: $spacer * 2 0 $spacer;
  }
  // need for ios devices so the modal doesn't break
  .modalOpen & {
    overflow: visible;
    @include forMdUp {
      overflow: hidden;
    }
  }
}
