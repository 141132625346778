@mixin wysiwyg {
  padding-bottom: $spacer * 1.25;
  padding-right: $spacer * 1.25;

  @include forSmUp {
    overflow: auto;
    max-height: $spacer * 15;
  }

  @media (min-width: $sm) and (min-height: $xxs) {
    max-height: $spacer * 20;
  }

  @media (min-width: $sm) and (min-height: $sm) {
    max-height: $spacer * 25;
  }
  @media (min-width: $md) {
    max-height: $spacer * 33;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 700;
    + * {
      margin-top: $spacer;
    }
  }
  h1 {
    font-size: $spacer * 2.25;
  }
  h2 {
    font-size: $spacer * 1.5;
  }
  h3 {
    font-size: $spacer * 1.17;
  }
  h4 {
    font-size: $spacer;
  }
  h5 {
    font-size: $spacer * 0.83;
  }
  h6 {
    font-size: $spacer * 0.67;
  }
  p {
    margin: $spacer 0;
  }
  a {
    color: $primaryColor;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline-color: $primaryColor;
    }
  }
  ul,
  ol {
    padding-left: $spacer;
    margin: 0;
  }

  li {
    margin: $spacer * 0.25 0;
  }
}
