@mixin fieldset {
  border: $borderBase $primaryColorDark;
  margin-bottom: $spacer * 1.5;
  padding: $spacer;

  &[disabled] {
    border-color: $grayLight;
    cursor: not-allowed;
  }
}
