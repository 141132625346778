@mixin requestHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include forMdUp {
    flex-direction: row;
    align-items: center;
  }

  .h2,
  h2 {
    margin: 0;
    @include forMdUp {
      line-height: $lineHeightSmaller;
    }
  }

  .buttonGroup,
  div {
    margin-top: $spacer;

    @include forMdUp {
      margin-top: 0;
      margin-left: auto;
    }
  }
}

@mixin requestMetadata {
  @include row;
  align-items: baseline;
  .column {
    @include column;
    @include columnAuto;
  }

  .h6,
  h6 {
    margin: 0;
  }

  .p,
  p {
    &:not(.validation) {
      font-family: $fontFamilyBase;
      font-size: $fontSizeMedium;
      font-weight: $fontWeightLight;
      margin: $spacer * 0.25 0 0;
      @include forXlUp {
        font-size: $fontSizeLarger;
      }
    }
  }
}
