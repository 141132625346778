@mixin logo {
  display: block;
  opacity: 0.9;
  padding: 0 $spacer;
  text-decoration: none;
  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: none;
  }
}

@mixin logoImage {
  display: block;
  height: auto;
  width: $spacer * 6.25;
  @include forMdUp {
    width: $spacer * 8.75;
  }
}
