@mixin note {
  border: $borderBase $grayLight;
  border-top: 0;
  padding: $spacer * 0.5;
  @include forMdUp {
    padding: $spacer;
  }
  &:first-of-type {
    border-top: $borderBase $grayLight;
  }
  &:nth-child(even) {
    background-color: $grayLightest;
  }
  .noteText {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;
    line-height: $lineHeightMedium;
    margin: 0;
  }
  .noteMetadata {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: $fontSizeSmaller;
    margin: $spacer * 0.25 0 0;
  }
  .noteDelete {
    font-size: $fontSizeSmall;
    margin: 0 $spacer * 0.5 0 0;
    padding: 0;
  }
  .noteSave {
    font-size: $fontSizeSmall;
    margin: 0 $spacer * 0.5 0 0;
    padding: 0;
  }
  .noteCount {
    font-size: $fontSizeSmall;
  }
}

@mixin notes {
  h6 {
    margin-bottom: $spacer;
    + .noteAdd {
      margin-top: 0;
    }
  }
  .noteAdd {
    margin-top: $spacer;
  }
}
