@mixin reactVirtualizedTable {
  margin-top: $spacer;
}

@mixin reactVirtualizedHeaderRow {
  background-color: $primaryColor;
  border: $borderBase $primaryColorDark;
  color: $white;
  display: none;
  font-size: 0.75rem;
  font-weight: 600;
  padding-right: 0 !important;
  text-align: left;
  text-transform: none;
  @media (min-width: 1024px) {
    display: flex;
  }
}

@mixin reactVirtualizedHeaderColumn {
  border-right: $borderBase $primaryColorDark;
  margin: 0;
  padding: $spacer * 0.5;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-child {
    border-right: 0;
  }
}

@mixin reactVirtualizedGrid {
  background-color: $white;
  border: $borderBase $gray;
  min-height: $spacer * 2;
  outline: 0;
  @media (min-width: 1024px) {
    border-top: 0;
  }
}

@mixin reactVirtualizedRow {
  display: block;
  border-top: $borderBase $grayLight;
  @media (min-width: 1024px) {
    display: flex;
  }
  &:first-child {
    border-top: 0;
  }
  &:nth-child(even) {
    background-color: lighten($grayLightest, 2%);
  }
  &:hover {
    background-color: $grayLightest;
  }
}

@mixin reactVirtualizedRowColumn {
  margin: 0;
  padding: 0 0 $spacer * 0.5 $spacer * 0.5;
  @media (min-width: 1024px) {
    border-right: $borderBase $grayLight;
    padding: $spacer * 0.75 0 $spacer * 0.75 $spacer * 0.5;
  }
  &:first-of-type {
    margin-left: 0;
  }
  &:first-child {
    padding-top: $spacer * 0.5;
    @media (min-width: 1024px) {
      padding-top: $spacer * 0.75;
    }
  }
  &:last-child {
    border-right: 0;
  }
  .inputWrapper,
  .selectWrapper,
  .datePicker {
    padding-right: $spacer * 0.5;
  }
  .inputWrapperRequired,
  .selectWrapperRequired {
    padding-right: $spacer * 0.625;
  }
  .required:after {
    right: $spacer * 0.125;
  }
}
