@mixin legend {
  background-color: $primaryColorDark;
  color: $white;
  font-size: $fontSizeSmaller;
  font-weight: $fontWeightBolder;
  text-align: center;
  text-transform: uppercase;
  padding: $spacer * 0.375 $spacer * 0.625;

  .fieldset[disabled] & {
    background-color: $grayLight;
  }
}
