@mixin titles {
  // payroll periods
  &[title="Start Date"] {
    .thInner {
      @include forMdUp {
        width: $spacer * 6.75;
      }
    }
  }
  &[title="End Date"] {
    .thInner {
      @include forMdUp {
        width: $spacer * 6.75;
      }
    }
  }
  &[title="Check Date"] {
    .thInner {
      @include forMdUp {
        width: $spacer * 6.75;
      }
    }
  }
  &[title="Active"] {
    .thInner {
      @include forMdUp {
        width: $spacer * 4;
      }
    }
  }
  &[title="Remove Payroll Period"] {
    .thInner {
      @include forMdUp {
        width: $spacer * 2.25;
      }
      @include forLgUp {
        width: $spacer * 6;
      }
    }
  }
  // requests list
  &[title="Edit"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        width: $spacer * 3.25;
      }
    }
  }
  &[title="Edit Or Export"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        width: $spacer * 9.875;
      }
    }
  }
  // request detail
  &[title="Select All Rows"],
  &[title="Select This Row"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        width: $spacer * 0.875;
      }
    }
  }

  &[title="First Name"],
  &[title="First"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }

  &[title="MI"],
  &[title="Middle Initial"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        width: $spacer * 1.75;
      }
    }
  }

  &[title="Last Name"],
  &[title="Last"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }

  &[title="Suffix"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 3;
        max-width: $spacer * 4;
      }
    }
  }

  &[title="Nickname"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 3;
        max-width: $spacer * 4;
      }
    }
  }

  &[title="PERNR"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }

  &[title="Amount"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 7;
      }
    }
  }

  &[title="Wage Type"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
      }
      @include forXxxlUp {
        min-width: $spacer * 12;
      }
      @include forXxxxlUp {
        min-width: $spacer * 16;
      }
    }
  }

  &[title="Cost Center"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }

  &[title="Authority"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }

  &[title="Comment"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
      }
      @include forXxxlUp {
        min-width: $spacer * 12;
      }
      @include forXxxxlUp {
        min-width: $spacer * 16;
      }
    }
  }

  &[title="Hours"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Number"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 7;
      }
    }
  }

  &[title="Units"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Rate"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 7;
      }
    }
  }

  &[title="Dollar Limit"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 7;
      }
    }
  }

  // request list
  &[title="ID"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 4;
      }
      @include forXxxxlUp {
        max-width: none;
      }
    }
  }

  &[title="Bottler"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
      @include forXxxlUp {
        max-width: $spacer * 11;
      }
      @include forXxxxlUp {
        max-width: $spacer * 13;
      }
    }
  }

  &[title="Tower"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
        max-width: $spacer * 6;
      }
      @include forXxxlUp {
        max-width: $spacer * 8;
      }
      @include forXxxxlUp {
        max-width: $spacer * 10;
      }
    }
  }

  &[title="Request Group"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
      @include forXxxlUp {
        max-width: $spacer * 11;
      }
      @include forXxxxlUp {
        max-width: $spacer * 13;
      }
    }
  }

  &[title="Request Type"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
      @include forXxxlUp {
        max-width: $spacer * 11;
      }
      @include forXxxxlUp {
        max-width: $spacer * 13;
      }
    }
  }

  &[title="Status"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }

  &[title="Requested"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8.5;
        max-width: $spacer * 8.5;
      }
    }
  }

  &[title="Requester"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
      @include forXxxlUp {
        max-width: $spacer * 10;
      }
      @include forXxxxlUp {
        max-width: $spacer * 12;
      }
    }
  }

  &[title="Requested Group"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 10;
      }
    }
  }

  &[title="Summary"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
      @include forXxxlUp {
        max-width: $spacer * 11;
      }
      @include forXxxxlUp {
        max-width: $spacer * 13;
      }
    }
  }

  &[title="Assigned To"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
      @include forXxxlUp {
        max-width: $spacer * 10;
      }
      @include forXxxxlUp {
        max-width: $spacer * 12;
      }
    }
  }

  &[title="Due Date"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8.5;
        max-width: $spacer * 8.5;
      }
    }
  }

  // Services App AD Groups list
  &[title="AD Group Name"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 5;
      }
    }
  }

  &[title="AD Group Description"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 3;
      }
    }
  }

  &[title="AD Group Member Count"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 7;
      }
    }
  }

  &[title="Actions"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 7;
      }
    }
  }
  // request type management
  &[title="SLA"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 5;
      }
    }
  }
  // logins and actions table
  &[title="Logged In At"],
  &[title="Login Created"],
  &[title="Lookup Created"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Equipment Lookup"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
      }
    }
  }
  &[title="Equipment Lookup Type"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 11;
      }
    }
  }
  &[title="Request"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 5;
      }
    }
  }
  &[title="Request Contact First Name"],
  &[title="Request Contact Last Name"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 12;
      }
    }
  }
  //
  &[title="Request ID"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
      }
    }
  }
  &[title="Request Process"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 10;
      }
    }
  }
  &[title="Audit Type"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
      }
    }
  }
  &[title="Request Priority"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 10;
      }
    }
  }

  &[title="Submittable"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 7;
      }
    }
  }

  // request errors
  &[title="Error ID"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 3;
        max-width: $spacer * 3;
      }
    }
  }
  &[title="Created At (ISO)"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 12;
        max-width: $spacer * 12;
      }
    }
  }
  &[title="Date Created"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 5;
        max-width: $spacer * 5;
      }
    }
  }
  &[title="Time Created"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 4;
      }
    }
  }
  &[title="Error Message"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 16;
        max-width: $spacer * 16;
      }
    }
  }
  &[title="Error Code"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
        max-width: $spacer * 6;
      }
    }
  }
  &[title="Error Number"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 7;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Error Type"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
        max-width: $spacer * 6;
      }
    }
  }
  &[title="Error Class"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 11;
        max-width: $spacer * 11;
      }
    }
  }
  &[title="Bottler"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="User Name"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="User Email"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 15;
        max-width: $spacer * 15;
      }
    }
  }
  &[title="Serial Number"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
    }
  }
  &[title="Equipment Number"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 11;
        max-width: $spacer * 11;
      }
    }
  }
  &[title="Equipment Type Code"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 10;
        max-width: $spacer * 10;
      }
    }
  }
  &[title="Equipment Type"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
    }
  }
  &[title="Equipment Problem"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 12;
        max-width: $spacer * 12;
      }
    }
  }
  &[title="Equipment Problem Code"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 7;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Equipment Problem Priority"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 5;
        max-width: $spacer * 5;
      }
    }
  }
  &[title="Outlet Name"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 12;
        max-width: $spacer * 12;
      }
    }
  }
  &[title="Outlet Number"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
    }
  }
  &[title="Outlet Street"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Outlet City"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Outlet State"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 4;
        max-width: $spacer * 4;
      }
    }
  }
  &[title="Outlet Postal Code"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 7;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Outlet Contact Name"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 7;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Outlet Contact Phone"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 7;
        max-width: $spacer * 7;
      }
    }
  }
  &[title="Outlet Contact Phone Extension"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 8.5;
        max-width: $spacer * 8.5;
      }
    }
  }
  &[title="Availability Days"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 10;
        max-width: $spacer * 10;
      }
    }
  }
  &[title="Availability Hours Start"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
        max-width: $spacer * 6;
      }
    }
  }
  &[title="Availability Hours End"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 6;
        max-width: $spacer * 6;
      }
    }
  }
  &[title="Equipment Location"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Request Comment"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Ticket Number"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Equipment Street"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Equipment City"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 9;
        max-width: $spacer * 9;
      }
    }
  }
  &[title="Equipment State"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
    }
  }
  &[title="Equipment Postal Code"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 11;
        max-width: $spacer * 11;
      }
    }
  }
  &[title="Employee Text Notification"],
  &[title="Employee Email Notification"],
  &[title="Request Contact Email Notification"],
  &[title="Request Contact Phone Notification"],
  &[title="Request Contact Text Notification"] {
    .thInner,
    .tdInner {
      @include forMdUp {
        min-width: $spacer * 15;
        max-width: $spacer * 15;
      }
    }
  }
  &[title="Request Contact Phone"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 11;
        max-width: $spacer * 11;
      }
    }
  }
  &[title="Request Contact Role"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 10;
        max-width: $spacer * 10;
      }
    }
  }
  &[title="Request Contact Email"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 14;
        max-width: $spacer * 14;
      }
    }
  }
  &[title="Employee Name"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 12;
        max-width: $spacer * 12;
      }
    }
  }
  &[title="Employee Email"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 14;
        max-width: $spacer * 14;
      }
    }
  }
  &[title="Employee Phone"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
    }
  }
  &[title="Problem Description"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 13;
      }
    }
  }
  &[title="Problem Code"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 8;
      }
    }
  }
  &[title="Admin User Name"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 10;
        max-width: $spacer * 10;
      }
    }
  }
  &[title="Admin User Email Address"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 12;
        max-width: $spacer * 12;
      }
    }
  }
  &[title="User Email Address"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 12;
        max-width: $spacer * 12;
      }
    }
  }
  &[title="AD Audit Group Name"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 16;
        max-width: $spacer * 16;
      }
    }
  }
  &[title="Action"] {
    > div {
      @include forMdUp {
        min-width: $spacer * 8;
        max-width: $spacer * 8;
      }
    }
  }
}
