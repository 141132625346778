@mixin validation {
  display: block;
  font-size: $fontSizeSmaller;
  font-weight: $fontWeightBolder;
  line-height: $lineHeightSmall;
  margin: 0;
  padding: $spacer * 0.375 $spacer * 0.5;
}

@mixin validationError {
  background-color: $errorColor;
  color: $errorColorInverse;
}
