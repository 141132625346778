@mixin listBase {
  padding: 0;
  margin: 0 0 $spacer;

  &:last-child {
    margin-bottom: 0;
  }

  &:only-child {
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }

  ol,
  ul {
    padding-left: $spacer * 1.5;
    margin-bottom: 0;
  }
}

@mixin listUnordered {
  @include listBase;
  list-style-position: inside;
  list-style-type: disc;
}

@mixin listOrdered {
  @include listBase;
  list-style-position: inside;
  list-style-type: decimal;
}

@mixin listNone {
  @include listBase;
  list-style: none;
}

@mixin list {
  @include listNone;
  margin: $spacer 0 0;
  padding: 0;
}

@mixin listItem {
  margin: 0;
  padding: $spacer;
}

@mixin listItemStriped {
  &:nth-child(even) {
    background-color: lighten($grayLightest, 2%);
  }
}

@mixin listItemBordered {
  border: $borderBase $grayLight;
  border-bottom: 0;

  &:last-child {
    border-bottom: $borderBase $grayLight;
  }
}

@mixin listItemHover {
  &:hover {
    background-color: $grayLightest;
  }
}

@mixin listItemStripedHover {
  @include listItemStriped;
  @include listItemHover;
}

@mixin listItemInner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  > .button {
    margin-left: $spacer * 0.25;
  }
}
