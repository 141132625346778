@mixin dragDrop {
  width: 100%;
  min-height: 100px;
  border: 2px dashed;
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: $spacer;
  margin-top: $spacer;
  @include boxSizing;

  p {
    font-size: 1.2em;
    margin-bottom: 0;
  }
}

@mixin dragAndDrop {
  @include dragDrop;
  background-color: $white;
  border: $borderWidthThick dashed $infoColor;
}

@mixin drapAndDropHover {
  @include dragDrop;
  background-color: lighten($successColor, 55%);
  border: $borderWidthThick dashed $successColor;
}

.dragAndDrop {
  @include dragAndDrop;
}

.dragged {
  @include dragAndDrop;
}