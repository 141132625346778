@import "_box-sizing.module.scss";
@import "_screen-readers.module.scss";

@mixin radio {
  align-items: center;
  display: flex;
  margin: $spacer * 0.25 0;
  position: relative;
  top: 0;
  @include boxSizingAll;

  &:hover {
    top: -1px;
  }

  .radiosInline & {
    &:not(:last-child) {
      margin-right: $spacer * 0.75;
    }
  }
}

@mixin radioInput {
  @include srOnly;
  &:checked {
    + .radioLabel {
      &:before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
      &:after {
        content: "";
        display: block;
        border-color: $white;
        border-style: solid;
        border-width: $spacer * 0.25;
        border-radius: 50%;
        width: $spacer * 0.5;
        height: $spacer * 0.5;
        position: absolute;
        top: $spacer * 0.25;
        left: $spacer * 0.25;
        margin: 0;
      }
    }
  }
  &[disabled] {
    + .radioLabel {
      cursor: not-allowed;
      &:before {
        background-color: $grayLighter;
        border: $borderBase $gray;
      }
    }
  }
}

@mixin radioLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightBold;
  line-height: 1;
  position: relative;
  margin: $spacer * 0.125 0 0;
  @include forMdUp {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightMedium;
  }
  &:before {
    content: "";
    background-color: $white;
    border: $borderBase $gray;
    border-radius: 50%;
    display: block;
    margin: 0 $spacer * 0.25 0 0;
    width: $spacer;
    height: $spacer;
  }
  &:hover {
    &:before {
      background-color: $grayLightest;
    }
  }
  &.radioLabelSrOnly {
    &:before {
      margin-right: 0;
    }
  }
}

@mixin radioText {
}

@mixin radios {
}

@mixin radiosInline {
  display: flex;
  flex-wrap: wrap;
}
