@import "./sass/coke-bsna.module.scss";

* {
  @include boxSizing;
}

html {
  @include html;
}

body {
  @include body;
}

.flex {
  @include flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.grid {
  @include grid;
}

.row {
  @include row;
}
.flexAlignCenter {
  @include flexAlignCenter;
}

.tabbar {
  @include tabbar;
}

.tabbarButton {
  @include tabbarButton;

  &.selected {
    @include tabbarButtonSelected;
  }
}

// TODO: Use for loop to generate columns
.column {
  @include column;
}

.columnSm {
  @include forSmUp {
    .row:first-child & {
      margin-top: 0;
    }
  }
}

.column1 {
  @include forSmUp {
    max-width: 8.33%;
    width: 8.33%;
  }
}

.column2 {
  @include forSmUp {
    max-width: 16.66%;
    width: 16.66%;
  }
}

.column3 {
  @include forSmUp {
    max-width: 25%;
    width: 25%;
  }
}

.column4 {
  @include forSmUp {
    max-width: 33.33%;
    width: 33.33%;
  }
}

.column6 {
  @include forSmUp {
    max-width: 50%;
    width: 50%;
  }
}

.column7 {
  @include forSmUp {
    max-width: 58.33%;
    width: 58.33%;
  }
}

.column8 {
  @include forSmUp {
    max-width: 66.66%;
    width: 66.66%;
  }
}

.column9 {
  @include forSmUp {
    max-width: 75%;
    width: 75%;
  }
}

.column10 {
  @include forSmUp {
    max-width: 80%;
    width: 80%;
  }
}

.column12 {
  @include forSmUp {
    max-width: 91.66%;
    width: 91.66%;
  }
}

//Basic Grid System
.flex-grid {
  display: flex;
  .col {
    flex: 1;
  }
}
.flex-grid-2 {
  display: flex;
  justify-content: space-between;
  .col {
    width: 49%;
  }
}
.flex-grid-3 {
  display: flex;
  justify-content: space-between;
  .col {
    width: 32%;
  }
}
.flex-grid-4 {
  display: flex;
  justify-content: space-between;
  .col {
    width: 24%;
  }
}
.flex-grid-5 {
  display: flex;
  justify-content: space-between;
  .col {
    width: 19%;
  }
}
.flex-grid-6 {
  display: flex;
  justify-content: space-between;
  .col {
    width: 15.6%;
  }
}

.flex-grid,
.flex-grid-2,
.flex-grid-3,
.flex-grid-4,
.flex-grid-5,
.flex-grid-6 {
  margin-bottom: $spacer * 0.75;
}

@media (max-width: 767px) {
  .flex-grid,
  .flex-grid-2,
  .flex-grid-3,
  .flex-grid-4,
  .flex-grid-5,
  .flex-grid-6 {
    display: block;
    .col {
      width: 100%;
      margin: 0 0 $spacer 0;
    }
  }
}

.wrapper {
  @include wrapper;
}

.wrap {
  @include wrap;
}

.header {
  @include header;
}

.logo {
  @include logo;
}

.logoImage {
  @include logoImage;
}

.container {
  @include container;
}

.content {
  @include content;
}

.contentInner {
  @include contentInner;
}

.navs {
  @include navs;
}

.navsOpen {
  @include navsOpen;
}

.navsInner {
  @include navsInner;
}

.primaryNav {
  @include nav;
}

.primaryNavInner {
  @include navInner;
}

.primaryNavBottom {
  @include navBottom;
}

.navLink {
  @include navLink;
}

.navLinkActive {
  @include navLinkActive;
}

.navLinkText {
  @include navLinkText;
}

.navLinkIcon {
  @include navLinkIcon;
}

.navLinkBorder {
  @include navLinkBorder;
}

.navLinkLoading {
  display: block;
  padding: $spacer * 0.75 $spacer * 0.25;
  width: 100%;
}

.navLinkIconLoading {
  display: block;
  margin: 0 auto;
  height: $spacer * 1.75;
  width: 50%;
  background: lighten($grayDark, 10%);
  background: linear-gradient(to right, lighten($grayDark, 10%) 10%, $grayDark 40%, lighten($grayDark, 10%) 60%);
  background-size: $spacer * 100 $spacer * 50;
  @include loaderGradientAnimation;
}

.navLinkTextLoading {
  display: block;
  margin: $spacer * 0.5 auto 0;
  height: $spacer * 0.375;
  width: 75%;
  background: lighten($grayDark, 10%);
  background: linear-gradient(to right, lighten($grayDark, 10%) 10%, $grayDark 40%, lighten($grayDark, 10%) 60%);
  background-size: $spacer * 100 $spacer * 50;
  @include loaderGradientAnimation;
}

.secondaryNav {
  @include subNav;

  .navsOpen & {
    left: $spacer * 6;
    right: auto;

    @include forMdUp {
      left: $spacer * 5;
    }
  }
}

.secondaryNavLinks {
  display: none;
}

.secondaryNavOpen {
  display: block;
}

.subNavLink {
  @include subNavLink;
}

.subNavLinkActive {
  @include subNavLinkActive;
}

.subNavIcon {
  @include subNavIcon;
}

.subNavText {
  @include subNavText;
}
.subNavSectionHeading {
  @include subNavSectionHeading;
}

.secondaryNavLinkLoading {
  display: flex;
  padding: $spacer * 0.625 $spacer * 0.75;
  width: 100%;
}

.secondaryNavLinkIconLoading {
  display: block;
  height: $spacer;
  min-width: $spacer;
  width: $spacer;
  background: $grayLighter;
  background: linear-gradient(to right, $grayLighter 10%, $grayLight 40%, $grayLighter 60%);
  background-size: $spacer * 100 $spacer * 50;
  @include loaderGradientAnimation;
}

.secondaryNavLinkTextLoading {
  display: block;
  margin-left: $spacer * 0.5;
  width: 100%;
}

.secondaryNavLinkText {
  display: block;
  height: $spacer;
  width: 100%;
  background: $grayLighter;
  background: linear-gradient(to right, $grayLighter 10%, $grayLight 40%, $grayLighter 60%);
  background-size: $spacer * 100 $spacer * 50;
  @include loaderGradientAnimation;

  &:not(:first-child) {
    margin-top: $spacer * 0.75;
  }
}

.main {
  @include main;
}

.footer {
  @include footer;
}

img {
  @include image;
}

a {
  @include link;
}

.linkDisabled {
  @include linkDisabled;
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}

p {
  @include paragraph;
}

ol {
  @include listOrdered;
}

ul {
  @include listUnordered;
}

strong,
b {
  @include bold;
}

.band {
  @include band;
}

.section {
  @include section;
}

.sectionInner {
  @include sectionInner;
}

.button {
  @include button;
}

.buttonIcon {
  @include buttonIcon;
}

.buttonIconLeft {
  @include buttonIconLeft;
}

.buttonText {
  @include buttonText;
}

.buttonLink {
  @include buttonPrimary;
}

.buttonLinkWhite {
  @include buttonLinkWhite;
}

.buttonWhite {
  @include buttonWhite;
}

.buttonCloseModal {
  @include buttonCloseModal;
}

.buttonPrimary {
  @include buttonPrimary;
}

.buttonInfo {
  @include buttonInfo;
}

.buttonSuccess {
  @include buttonSuccess;
}

.buttonSave {
  @include buttonSave;
}

.buttonGeneral {
  @include buttonGeneral;
}

.buttonGeneralSelected {
  @include buttonGeneralSelected;
}

.buttonHistory {
  @include buttonLinkGrayDark;
  font-size: $fontSizeSmall;
  margin-left: $spacer * 0.5;
  padding: 0;
}

.buttonLarge {
  @include buttonLarge;
}

.buttonLarger {
  @include buttonLarger;
}

.buttonLargest {
  @include buttonLargest;
}

.buttonLinkPrimaryColor {
  @include buttonLinkPrimaryColor;
}

.buttonLinkSuccessColor {
  @include buttonLinkSuccessColor;
}

.buttonLinkInfoColor {
  @include buttonLinkInfoColor;
}

.buttonOutlinePrimary {
  background-color: $white;
  border: $borderBase $primaryColor;
  color: $primaryColor;

  &:hover,
  &:focus {
    background-color: $primaryColor;
    color: $white;
  }
}

.buttonBlock {
  width: 100%;
  text-align: center;
}

.buttonLinkBlock {
  @include buttonPrimary;
  width: 100%;
  text-align: center;
  font-weight: $fontWeightLighter;
}

.buttonLinkIconLeft {
  margin-right: $spacer * 0.25;
  margin-left: 0;
  [role="presentation"] {
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}

.buttonLinkIconRight {
  margin-left: $spacer * 0.25;
  margin-right: 0;
  [role="presentation"] {
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}

.buttonSort {
  @include buttonSort;
}

.buttonTogglePassword {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.openMenu {
  @include openMenu;
}

.closeMenu {
  @include closeMenu;
}

.overlay {
  @include overlay;
}

.loggedInAs {
  @include loggedInAs;
}

.loggedInAsButton {
  @include loggedInAsButton;
}

.loggedInAsName {
  @include loggedInAsName;
}

.loggedInAsPhoto {
  @include loggedInAsPhoto;
}

.loggedInAsImage {
  @include loggedInAsImage;
}

.loggedInAsPlaceholder {
  @include loggedInAsPlaceholder;
  flex-shrink: 0;
}

.loggedInAsDropdownList {
  @include loggedInAsDropdownList;
}

.loggedInAsLink {
  @include loggedInAsLink;
}

.avatar {
  align-items: center;
  display: flex;
  margin: $spacer * 0.5 0 0;
  overflow: hidden;

  .loggedInAs & {
    margin: 0;
  }
}

@mixin textEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.avatarName {
  display: block;
  font-size: $fontSizeSmall;
  margin: 0 0 0 $spacer * 0.5;
  @include textEllipsis;
}

.select {
  @include select;
}

.selectRequired {
  @include selectRequired;
}

.required {
  @include required;
}

.selectWrapper {
  @include selectWrapper;
}

.selectWrapperRequired {
  @include selectWrapperRequired;
}

.selectLarge {
  @include selectLarge;
}

.selectLarger {
  @include selectLarger;
}

.selectLargest {
  @include selectLargest;
}

.hr {
  @include hr;
}

.tableWrapper {
  @include tableWrapper;
}

.tableLoading {
  min-height: $spacer * 12;
}

.table {
  @include table;
}

.tdInner {
  word-break: break-word;

  .tableEllipsis & {
    @include forMdUp {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      word-break: normal;
    }
  }
}

.thead {
  @include thead;
}

.tbody {
  @include tbody;
}

.tr {
  @include tr;
}

.trHover {
  @include trHover;
}

.trStriped {
  @include trStriped;
}

.trStripedHover {
  @include trStripedHover;
}

.trSuccess {
  @include trSuccess;
}

.trError {
  @include trError;
}

.trWarning {
  @include trWarning;
}

.trInfo {
  @include trInfo;
}

.trNotMatched {
  @include trNotMatched;
}

.trChecked {
  @include trChecked;
}

.th {
  @include th;
  height: $spacer * 2.75;
}

.td {
  @include td;
}

.tdWithLabel {
  .label {
    @include forMdUp {
      display: none;
    }
  }
}

.tfoot {
  @include tfoot;
}

.label {
  @include label;
}

.labelSubText {
  color: $gray;
  display: block;
  font-size: $fontSizeSmall;
  font-style: italic;
  font-weight: $fontWeightMedium;
  margin: $spacer * 0.375 0 $spacer * 0.5;
}

.input {
  @include input;
}

.inputWrapper {
  @include inputWrapper;
}

.inputRequired {
  @include inputRequired;
}

.inputWrapperRequired {
  @include inputWrapperRequired;
}

.inputShowPassword {
  @include inputShowPassword;
}

.inputGroupWrapper {
  @include inputGroupWrapper;
}

.inputGroup {
  @include inputGroup;
}

.inputGroupAppend {
  @include inputGroupAppend;
}

.textarea {
  @include textarea;
}

.textareaWrapper {
  @include textareaWrapper;
}

.textareaRequired {
  @include textareaRequired;
}

.textareaWrapperRequired {
  @include textareaWrapperRequired;
}

.loaderWrapper {
  @include loaderWrapper;
}

.loader {
  @include loader;
}

.loaderCentered {
  @include loaderCentered;
}

.loaderTop {
  @include loaderTop;
}

.loaderOverlay {
  @include loaderOverlay;
}

.loaderInline {
  align-items: center;
  display: flex;
  padding: $spacer 0;

  .loaderImageWrapper {
    margin: 0;
    width: $spacer * 2;
    height: $spacer * 2;
  }

  .loaderCircle {
    width: $spacer * 2;
    height: $spacer * 2;
  }

  &.hasLoaderImage {
    min-height: 0;
  }
}

.loaderFullscreen {
  @include loaderFullscreen;
}

.loaderImageWrapper {
  @include loaderImageWrapper;
}

.loaderImage {
  @include loaderImage;
}

.loaderCircle {
  @include loaderCircle;
}

.hasLoaderImage {
  @include hasLoaderImage;
}

.loaderText {
  @include loaderText;
}

.loaderGradient {
  @include loaderGradient;
}

.checkbox {
  @include checkbox;
}

.check {
  @include check;
}

.checkLabel {
  @include checkLabel;
}

.radio {
  @include radio;
}

.radioInput {
  @include radioInput;
}

.radioLabel {
  @include radioLabel;
}

.radioText {
  @include radioText;
}

.radios {
  @include radios;
}

.radiosInline {
  @include radiosInline;
}

.datePicker {
  @include datePicker;
}

.datePickerRequired {
  @include datePickerRequired;

  .label + &,
  .h6 + & {
    margin: $spacer * 0.25 0 0;
  }
}

.dateTimePickerWrapper {
  display: flex;

  .react-kronos {
    position: relative;

    * {
      font-family: $fontFamilyBase;
      font-size: 0.875rem;
      font-weight: $fontWeightMedium;
      line-height: $lineHeightSmall;
    }
  }

  .dateTimePickerDate,
  .dateTimePickerTime {
    position: relative;
    width: $spacer * 7.25;

    .dateTimePicker {
      border-radius: 0;
    }

    i {
      position: absolute;
      top: 0.25rem;
      right: 0.5rem;
    }

    .label {
      font-size: $fontSizeMedium;
      line-height: $lineHeightMedium;
      margin: 0;
    }
    .labelText {
      display: none;
    }
  }

  .dateTimePickerTime {
    margin-left: $spacer * 0.5;
    width: $spacer * 6;

    i {
      top: 0.125rem;
    }
  }

  .dateTimePicker {
    @include input;
    padding: $spacer * 0.125 $spacer * 0.5 $spacer * 0.25;

    &.outside-range {
      background-color: $white;
      color: $grayDarkest;
    }

    + div {
      top: calc(100% + 1px);
      border: $borderBase $gray;
      border-radius: 0;
      box-shadow: 0 $spacer * 0.5 $spacer rgba($black, 0.1);
      padding: $spacer * 0.5;

      > .hours {
        width: $spacer * 4.875;
        height: $spacer * 13.5;
      }
    }
  }

  .days,
  .months,
  .hours {
    border-radius: 0;
    display: inline-block;
    &.header {
      background-color: $grayLighter;
      color: $grayDarkest;
    }

    &.selected {
      background-color: $infoColor;

      &.outside-range {
        background-color: $infoColor;
      }

      &.today {
        color: $white;
      }
    }

    &.today {
      color: $infoColor;
      border: $borderBase;
      font-weight: $fontWeightBolder;
      border-color: $infoColor;
    }

    &:not(.selected).outside-range {
      color: $grayLighter;
    }

    &:not(.selected):not(.header):hover.outside-range {
      background-color: $white;
      color: $grayLighter;
    }

    > div {
      &:last-child {
        // today
        border-radius: 0;
        border: 0;

        &:hover {
          color: $infoColor;
        }
      }
    }
  }

  .arrow,
  .title {
    border-radius: 0;
    border: 0;

    &:hover {
      color: $infoColor;
    }
  }

  .arrow {
    margin-top: -7px;
  }

  .h6 + &,
  .label + & {
    margin-top: $spacer * 0.25;
  }
}

.dateRangePickerWrapper {
  align-items: center;
  border: $borderBase $gray;
  display: flex;
  max-width: $spacer * 18.5;
  padding: $spacer * 0.5 $spacer * 0.25 $spacer * 0.25;

  .dateTimePickerDate {
    width: $spacer * 7;

    @include forXsUp {
      width: $spacer * 8;
    }

    i {
      top: $spacer * 0.175;
    }
  }

  .dateTimePicker {
    font-weight: 300;
    border: 0;

    @include forXsUp {
      font-size: $fontSizeLarge;
    }
  }
}

.dateRangePickerIcon {
  margin: -($spacer * 0.25) $spacer * 0.375 0;
}

.payrollPeriods {
  @include payrollPeriods;
}

.switcher {
  .switcherInner {
    align-items: baseline;
    @include forXsUp {
      display: flex;
    }
  }
  .h2 {
    line-height: 1.1;
    @include forXsUp {
      margin: 0 $spacer 0 0;
    }
  }
  .switcherInput {
    @include forXsUp {
      max-width: $spacer * 30;
    }
  }
}

.toolbarXxs {
  @include toolbarXxs;
}

.toolbarSm {
  @include toolbarSm;
}

.srOnly {
  @include srOnly;
}

.srOnlyMd {
  @include forMdUp {
    @include srOnly;
  }
}

.displayNone {
  @include displayNone;
}

.marginNone {
  margin: 0;
}

.marginBottomSmall {
  margin-bottom: $spacer * 0.75;
}

.marginBottomSmaller {
  margin-bottom: $spacer * 0.5;
}

.marginBottomSmallest {
  margin-bottom: $spacer * 0.125;
}

.marginRight {
  margin-right: $spacer;
}

.notFound {
  @include notFound;
}

.notAllowed {
  @include notFound;
}

.logout {
  @include logout;
}

.activate {
  @include fullscreen;
}

.login {
  @include login;
}

.resetPassword {
  @include fullscreen;
}

.form {
  @include form;
}

.formInner {
  @include modalBodyPadding;
}

.field {
  @include field;
}

.fieldset {
  @include fieldset;
}

.legend {
  @include legend;
}

.validation {
  @include validation;
}

.validationError {
  @include validationError;
}

.alert {
  @include alert;
}

.alertIcon {
  @include alertIcon;
}

.alertMessage {
  @include alertMessage;
}

.alertSuccess {
  @include alertSuccess;
}

.alertError {
  @include alertError;
}

.alertWarning {
  @include alertWarning;
}

.alertInfo {
  @include alertInfo;
}

.addUser {
  @include addUser;
}

.manageUsers {
  @include manageUsers;
}

.textCenter {
  text-align: center;
}

.search {
  @include forXxxsUp {
    display: flex;
  }

  > .inputWrapper {
    @include forXxxsUp {
      max-width: $spacer * 10;
    }

    > .input {
      height: $spacer * 2.25;
    }
  }

  > .buttonWhite {
    margin-top: $spacer;

    @include forXxxsUp {
      margin-left: $spacer * 0.5;
      margin-top: 0;
    }
  }

  > .searchAction {
    @include forXxxsUp {
      margin-left: auto;
    }
  }
}

.gridSorter {
  margin-top: $spacer;

  @include forMdUp {
    display: none;
  }
}

.pagination {
  @include pagination;
}

.paginationList {
  @include paginationList;
}

.paginationListItem {
  @include paginationListItem;
}

.paginationButton {
  @include paginationButton;
}

.paginationButtonActive {
  @include paginationButtonActive;
}

.requestList,
.requestErrors,
.groupUserAudits {
  .tableWrapper {
    margin-top: $spacer;

    @include forMdUp {
      min-height: $spacer * 32.5;
      background-color: $grayLighter;
    }
  }

  .table {
    .td {
      font-size: $fontSizeSmall;
      .label {
        @include forMdUp {
          display: none;
        }
      }
    }

    .buttonLinkPrimaryColor {
      padding: $spacer * 0.25;
    }

    .alert {
      &:only-child {
        min-height: $spacer * 10;

        @include forMdUp {
          min-height: $spacer * 28;
        }
      }
    }
  }
}

.badge {
  @include badge;
}

.status {
  display: flex;
  align-items: baseline;
  i {
    margin-right: $spacer * 0.25;
  }
}

.requestHeader {
  @include requestHeader;
}

.requestMetadata {
  @include requestMetadata;
}

.tasks {
  .hr {
    margin-bottom: $spacer;
  }
  .tableWrapper {
    margin-top: $spacer;
  }
  .table {
    @include forMdUp {
      min-width: 1024px;
    }
  }
  .buttonDelete {
    font-size: $fontSizeMedium;
    padding: $spacer * 0.125 $spacer * 0.25;
  }
}

.taskbar {
  display: flex;

  > * {
    margin: $spacer $spacer * 0.25 0 0;

    &:nth-child(3) {
      margin-left: auto;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.editing {
  .label {
    @include forMdUp {
      @include srOnly;
    }
  }
  .checkbox {
    @include forMdUp {
      height: 30px;
    }
  }
  .checkLabel {
    @include forMdUp {
      margin: 0;
    }
    &:before {
      @include forMdUp {
        margin: 0;
      }
    }
  }
  .checkText {
    @include forMdUp {
      @include srOnly;
    }
  }
  .tip {
    height: $spacer * 1.375;
  }
}

.editInner {
  @include forMdUp {
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
  }
}

.editingIT0015 {
  @include forMdUp {
    min-width: 1500px;
  }
}
.editingIT0014 {
  @include forMdUp {
    min-width: 1600px;
  }
}
.editingIT2010 {
  @include forMdUp {
    min-width: 1700px;
  }
}

.merging {
  .label {
    @include forMdUp {
      @include srOnly;
    }
  }
}

.checking {
  .label {
    @include forMdUp {
      @include srOnly;
    }
  }
}

.foot {
  @include foot;
}

.checkboxColumn {
  overflow: visible !important;
}

.wizard {
  display: block;

  &[aria-hidden="true"] {
    display: none;
  }
}

.duplicatesControls {
  display: flex;
  align-items: center;
  margin-top: $spacer;

  button {
    margin-right: $spacer * 0.25;
    &:last-child {
      margin-right: 0;
    }
  }
}

.duplicatesStep {
  margin-left: auto;
}

// dropdowns
.dropdown {
  @include dropdown;
}

.dropdownList {
  @include dropdownList;
}

// filter
.filter {
  display: inline-block;

  .dropdown {
    > .button {
      color: $white;
      font-size: 1rem;
      padding: $spacer * 0.25 $spacer * 0.5;
      z-index: 2;

      &:hover,
      &:focus {
        color: $grayLightest;
      }
    }

    &[aria-expanded="true"] {
      > .button {
        background-color: $white;
        border: $borderBase $gray;
        border-bottom: $borderBase $white;
        color: $black;
      }
    }
  }

  .dropdownList {
    background-color: $white;
    box-shadow: 0 $spacer * 0.5 $spacer rgba($black, 0.1);
    border: $borderBase $gray;
    color: $grayDarkest;
    max-height: $spacer * 20;
    min-width: $spacer * 15;
    overflow-y: auto;
    padding: $spacer * 0.75;
    top: calc(100% - 2px);
  }

  // checkboxes
  .checkbox {
    margin: $spacer * 0.75 0 0;
  }

  .checkboxHr {
    margin: 0;
    border-bottom: $borderBase $grayLighter;
    padding-bottom: $spacer * 0.75;

    &:not(:first-child) {
      padding-top: $spacer * 0.75;
    }
  }

  .checkLabel {
    align-items: flex-start;
    color: $black;
    font-size: 1em;
    line-height: 1.4;
    word-break: break-word;
  }

  .field {
    &:not(:first-child) {
      margin-top: $spacer;
    }
  }

  .label {
    font-size: 1em;
  }

  .dateTimePickerWrapper {
    .dateTimePicker {
      font-size: 1em;
    }
  }
}

// filter with contains
.filterContains {
  display: inline-block;
  position: relative;

  &[aria-expanded="true"] {
    .filterContainsDropdownLeft,
    .filterContainsDropdownRight {
      display: block;
    }
  }
}

.filterContainsDropdownButton {
  @include button;
  background-color: transparent;
  border-color: transparent;
  color: $white;
  padding: 0 $spacer * 0.25;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    border-color: transparent;
    color: $white;
  }

  &[disabled] {
    &:hover,
    &:focus {
      background-color: transparent;
      border-color: transparent;
    }
  }

  span,
  [role="presentation"]:first-child {
    margin: 0;
  }

  [aria-expanded="true"] & {
    background-color: $primaryColorDark;

    &:hover,
    &:focus {
      top: 0;
    }
  }
}

@mixin filterContainsDropdown {
  background-color: $primaryColorDark;
  display: none;
  max-width: $spacer * 20;
  min-width: $spacer * 8;
  padding: $spacer * 0.5;
  position: absolute;
  z-index: 1;

  > div {
    width: 100%;
    margin-bottom: $spacer * 0.25;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.filterContainsDropdownLeft {
  @include filterContainsDropdown;
  left: 0;
}

.filterContainsDropdownRight {
  @include filterContainsDropdown;
  right: 0;
}

.filterContainsButton {
  @include button;
  background-color: $infoColor;
  border-color: $infoColor;
  color: $white;
  display: block;
  padding: $spacer * 0.25 $spacer * 0.5;
  text-align: center;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $infoColorDark;
    border-color: $infoColorDark;
    color: $white;
  }

  &[disabled] {
    &:hover,
    &:focus {
      background-color: $infoColorDark;
      border-color: $infoColorDark;
      color: $white;
    }
  }
}

.filterRight {
  .dropdownList {
    right: 0;
  }
}

.hasCalendar {
  .dropdownList {
    min-height: $spacer * 24;
  }
}

.requestSaveButton {
  @include buttonSave;
  font-size: 1.375rem;
  padding: 0.625em 1em 0.75em;
  [aria-expanded="true"] > & {
    background-color: $saveColorDark;
    border-color: $saveColorDark;
  }
}

.requestSaveDropdownButton {
  @include button;
  background-color: $grayLight;
  border-color: $grayLight;
  border-bottom: $borderBase $grayLighter;
  color: $grayDarkest;
  font-size: $fontSizeMedium;
  &:hover,
  &:focus {
    background-color: $saveColor;
    border-color: $saveColor;
    border-bottom-color: $grayLighter;
    color: $white;
  }
  &:last-child {
    border-bottom-color: $grayLight;
    &:hover,
    &:focus {
      background-color: $saveColor;
      border-color: $saveColor;
    }
  }
  i {
    font-size: $fontSizeSmall;
    vertical-align: -1px;
  }
}

.exportRequest {
  font-size: 1.375rem;
  padding: 0.625em 1em 0.75em;
  &:hover,
  &:focus {
    top: 0;
  }
  i {
    font-size: 1.125rem;
    margin-right: $spacer * 0.5;
    vertical-align: -2px;
  }
}

.exportHistory {
  @include listNone;
  font-size: $fontSizeSmall;
  font-style: italic;
  margin: $spacer 0 0;
  &:empty {
    display: none;
  }
  + .exportHistory {
    margin: 0;
  }
}

.requestExport {
  display: inline-block;
  cursor: default;
  .modal {
    .exportHistory,
    p {
      font-size: $fontSizeMedium;
    }
  }
}

.requestActions {
  display: flex;
  flex-wrap: wrap;
  .requestsSave {
    margin: 0 $spacer $spacer 0;
    @include forMdUp {
      margin: 0 $spacer * 2 $spacer * 2 0;
    }
  }

  .requestExport {
    display: block;
  }
}

.toggleMore {
  margin: $spacer * 0.5 0 0;
  padding: 0;
}

.marginTopNone {
  @include marginTopNone;
}

.marginBottomNone {
  @include marginBottomNone;
}

.tip {
  @include tooltip;
}

.tipTop {
  @include tooltip;
  @include tooltipTop;
}

.tipRight {
  @include tooltip;
  @include tooltipRight;
}

.notes {
  @include notes;
}

.note {
  @include note;
}

.modalWrapper {
  @include modalWrapper;
}

.modal {
  @include modal;
}

.modalIsOpening {
  @include modalIsOpening;
}

.modalIsClosing {
  @include modalIsClosing;
}

.modalInnerXxxs {
  @include modalInner;
  max-width: 250px;
}

.modalInnerXxs {
  @include modalInner;
  max-width: 500px;
}

.modalInnerXs {
  @include modalInner;
  max-width: 700px;
}

.modalInnerSm {
  @include modalInner;
  max-width: 900px;
}

.modalInner,
.modalInnerMd {
  @include modalInner;
  max-width: 1100px;
}

.modalInnerLg {
  @include modalInner;
  max-width: 1300px;
}

.modalInnerXl {
  @include modalInner;
  max-width: 1500px;
}

.modalInnerXxl {
  @include modalInner;
  max-width: 1700px;
}

.modalInnerXxxl {
  @include modalInner;
  max-width: 1900px;
}

.modalInnerXxxxl {
  @include modalInner;
  max-width: 2100px;
}

.modalHeader {
  @include modalHeader;
}

.modalBody {
  @include modalBody;
}

.modalBodyPadding {
  @include modalBodyPadding;
}

.modalFooter {
  @include modalFooter;
}

@include fade;

.ReactVirtualized__Table {
  @include reactVirtualizedTable;
}

.ReactVirtualized__Table__headerRow {
  @include reactVirtualizedHeaderRow;
}

.ReactVirtualized__Table__rowColumn {
  @include reactVirtualizedRowColumn;
}

.ReactVirtualized__Table__headerColumn {
  @include reactVirtualizedHeaderColumn;
}
.ReactVirtualized__Grid {
  @include reactVirtualizedGrid;
}

.ReactVirtualized__Table__row {
  @include reactVirtualizedRow;
  &.notMatched {
    @include notMatched;
  }
  &.checked {
    @include checked;
  }
}

.cards {
  @include cards;
}

.cardsCentered {
  @include cardsCentered;
}

.cardsTwoColumns {
  @include cardsTwoColumns;
}

.cardWrapper {
  @include cardWrapper;
}

.card {
  @include card;
}

.cardLink {
  @include cardLink;
}

.cardInner {
  @include cardInner;
}

.cardHeader {
  @include cardHeader;
}

.cardBody {
  @include cardBody;
}

.cardFooter {
  @include cardFooter;
}

.processing {
  margin-bottom: $spacer * 0.25;

  &:last-child {
    margin-bottom: 0;
  }
}

.processingErrors {
  margin-left: $spacer;
}

.processingError {
  color: $errorColor;
  list-style: none;
  i {
    vertical-align: -2px;
  }
}

.buttonGroup {
  display: flex;
  > button {
    &:first-child {
      margin-right: 1px;
    }
    &:last-child {
      margin-left: 1px;
    }
  }
}

// Admin Dashboard Cards
.dashboard3Cards {
  > .cardWrapper {
    flex: 0 0 100%;
    margin-top: $spacer;

    @include forXsUp {
      flex: 0 0 50%;
      margin-top: 0;
      max-width: 50%;
      min-height: $spacer * 20;
    }

    @include forLgUp {
      flex: 0 0 33.3%;
      max-width: 33.3%;
    }
  }
  i {
    font-size: 0.875em;
    vertical-align: -1px;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.3;
    text-align: center;

    @include forXxsUp {
      font-size: 2em;
    }
  }
}
.dashboardCards {
  > .cardWrapper {
    flex: 0 0 100%;
    margin-top: $spacer;

    @include forXsUp {
      flex: 0 0 50%;
      margin-top: 0;
      max-width: 50%;
      min-height: $spacer * 20;
    }

    @include forLgUp {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  i {
    font-size: 0.875em;
    vertical-align: -1px;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.3;
    text-align: center;

    @include forXxsUp {
      font-size: 2em;
    }
  }
}

.dashboard {
  .card {
    background: $grayLightest;
  }
}

.amount {
  color: $black;
  font-size: 120px;
  font-size: 15vw;
  font-weight: 500;
  line-height: 120px;
  line-height: 15vw;
  margin: 0 0 $spacer * 0.5;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 120px;
    line-height: 120px;
  }
}

.type {
  color: $black;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.3;
  margin: 0;
  text-align: center;
}

.time {
  color: $grayDark;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  margin: $spacer * 0.25 0 $spacer * 0.5;
  text-align: center;
  text-transform: uppercase;
}

.serviceRequest {
  color: $black;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin: 0 0 $spacer;
}

.buttonLinks {
  > a {
    margin-bottom: $spacer;

    &:last-child {
      margin-bottom: 0;
    }
    > span > div {
      display: inline-block;
      line-height: 1;
      vertical-align: -4px;
      margin-left: $spacer * 0.25;
      [role="presentation"] {
        font-size: 1.25rem;
        line-height: 1;
        vertical-align: -2px;
      }
    }
  }
}

.requestDetail {
  .h1 {
    a {
      text-decoration: none;
      border-bottom: $borderBase $infoColor;
      &:visited {
        border-bottom-color: $infoColorDarker;
      }
      &:hover,
      &:focus {
        border-bottom-color: $infoColorDarker;
      }
      &:active {
        border-bottom-color: $infoColor;
      }
    }
  }
  .requestMetadata {
    .column {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .requestFormInputs {
    margin-top: $spacer;
  }
}

.w-100 {
  width: 100%;
}

.documents {
  display: block;
  margin-top: $spacer;

  > h6 {
    margin: 0;
  }
}

.docs {
  padding: 0;
  margin: 0;
  list-style: none;

  &:empty {
    display: none;
  }
}

.doc {
  margin: $spacer * 0.5 0 0;

  &:first-child {
    margin-top: $spacer;
  }
}

.docNew {
  margin: $spacer * 0.75 0 0;
}

.docLink {
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;

  i {
    font-size: $fontSizeSmall;
    margin-right: $spacer * 0.25;
    vertical-align: -2px;
  }
}

.docUpload {
  color: $black;
  display: inline-block;
  margin: 0 $spacer * 0.5 0 0;

  span {
    display: block;
  }

  em {
    display: block;
    font-size: $fontSizeSmaller;
    color: $gray;
    text-transform: uppercase;
  }
}

.docDelete {
  padding: 0;
}

.docNewDelete {
  vertical-align: top;
}

.dragDrop {
  @include dragAndDrop;
}

.dragDropHover {
  @include drapAndDropHover;
}

.equipUploadSwitcher {
  margin-left: 0;
}

.equipmentUplaodFileBox {
  margin: 0;
}

.equipmentUploadButton {
  @include buttonPrimary;
  margin: $spacer * 2 0;
}

.equipUploadDocInfo {
  margin: $spacer * 2 0;
}

.equipUploadDocLabel {
  margin-right: $spacer;
  font-size: $fontSizeLarger;
}

.equipUploadDocName {
  margin-left: $spacer / 2;
  color: $quaternaryColor;
  font-size: $fontSizeLarger;
}

.equipUploadDelete {
  font-size: $fontSizeLarger;
}

.DateInput_input {
  text-align: center;
}

.barTooltip {
  padding: $spacer;
  border: $borderBase $grayLight;
  background-color: $white;

  .heading {
    font-size: $fontSizeMedium;
    color: $black;
    margin: 0;
  }

  .label {
    font-size: $fontSizeMedium;
    font-weight: $fontWeightBolder;
    color: $primaryColor;
    margin: 0;
  }
}

.reactSelect {
  font-family: $fontFamilyBase;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightMedium;
  line-height: $lineHeightSmall;

  .label + &,
  .h6 + & {
    margin: $spacer * 0.25 0 0;
  }

  .reactSelect__control {
    background-color: $white;
    border: $borderBase $gray;
    border-radius: 0;
    height: $spacer * 1.875;
    min-height: 0;
  }

  .reactSelect__control--is-focused {
    outline: 0;
    border-color: $infoColor;
    box-shadow: none;
  }

  .reactSelect__menu {
    border-radius: 0;
    box-shadow: 0 $spacer * 0.5 $spacer rgba($black, 0.1);
    border: $borderBase $gray;
    margin: $spacer * 0.125 0 0;
  }

  .reactSelect__menu-list {
    padding: 0;
  }

  .reactSelect__option--is-focused {
    background-color: $infoColorLightest;
  }

  .reactSelect__option--is-selected {
    background-color: $infoColor;
  }

  .reactSelect__value-container {
    padding: 0 $spacer * 0.375;
  }

  .reactSelect__single-value,
  .reactSelect__placeholder {
    line-height: 2;
    margin: 0 0 0 $spacer * 0.25;

    + div {
      padding: 0;
    }
  }

  .reactSelect__indicators {
    height: $spacer * 1.875;
  }

  .reactSelect__indicator-separator {
    margin-top: $spacer * 0.25;
    height: $spacer * 1.25;
  }

  .reactSelect__dropdown-indicator {
    padding: 0 $spacer * 0.25;
  }
}

// edge only
@supports (-ms-ime-align: auto) {
  .reactSelect__value-container {
    top: -($spacer * 0.125);
  }
}

.assignedTo {
  min-width: $spacer * 13;
}

.requestPrioritiesSelect {
  min-width: $spacer * 10;
}

.requestTypeSelect {
  min-width: $spacer * 15;

  .reactSelect__menu {
    min-width: $spacer * 18;
  }
}

.dueDate {
  max-width: $spacer * 7;
}

.actionButton {
  &.buttonIconLeft {
    margin-right: $spacer * 0.25;
  }
}

.table {
  .buttonLinkPrimaryColor,
  .buttonLinkSuccessColor {
    padding: 0.25rem;
  }
}

// Documentation Styles
// Grid Adjustments
.documentation {
  .all-center {
    justify-content: center;
    align-items: center;
    background-color: $white;
    flex-wrap: wrap;

    img {
      @media only screen and (max-width: 768px) {
        margin-top: $spacer;
      }
    }
  }

  h1 {
    margin-bottom: 0.75rem;
  }

  h3 {
    margin-bottom: $spacer;
    color: $primaryColor;
  }

  .container + .container {
    margin-top: $spacer * 2;
    flex-wrap: wrap;
  }

  .white-bg {
    background-color: $white;
  }

  .col-25 {
    width: 25%;
    padding: 0 $spacer;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .col-33 {
    width: 33%;
    padding: $spacer;
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
  }

  .col-50 {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .col-75 {
    width: 75%;
    padding: 0 $spacer;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .buttonLinkBlock {
    max-width: 250px;
  }

  .pull-up {
    margin-top: $spacer * -0.5;
  }

  .lead {
    font-size: 1.35rem;
    @media only screen and (max-width: 768px) {
      font-size: $spacer;
    }
  }
}

.helpText {
  color: $gray;
  font-size: $fontSizeSmaller;
  font-style: italic;
  margin-top: $spacer * 0.25;
}

// groupMembership
// adGroups
// adGroupsList
.adGroups,
.adGroupsList,
.defaultTable {
  .search {
    margin: 0 0 $spacer;
  }

  .tableWrapper {
    margin: $spacer 0 0;
  }

  .table {
    .td {
      font-size: $fontSizeSmall;
    }

    .buttonLinkPrimaryColor {
      padding: $spacer * 0.25;
    }

    .alert {
      &:only-child {
        min-height: $spacer * 6;
      }
    }
  }

  .tdInner {
    word-wrap: break-word;

    > .label {
      @include forMdUp {
        display: none;
      }
    }
  }

  .tdInnerCenter {
    @include forMdUp {
      text-align: center;
    }
  }
}

// text colors
.colorRed {
  color: $primaryColor;
}

.colorGray {
  color: $gray;
}

.italic {
  font-style: italic;
}

//modal styles
/* Tables
================================== */
.Rtable-row {
  border-bottom: $borderBase $grayLighter;
  padding: $spacer * 0.5 $spacer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  clear: both;

  @include forSmUp {
    flex-direction: row;
  }

  &:nth-child(odd) {
    background-color: $grayLightest;
  }

  &:first-child {
    border-top: none;
    background-color: $white;
  }

  .Rtable-cell {
    width: 100%;
    line-height: 2.5;
    @include forSmUp {
      width: 33%;
    }

    &.wide-cell {
      width: 100%;
      @include forSmUp {
        width: 42%;
      }
    }

    &.narrow-cell {
      width: 100%;
      @include forSmUp {
        width: 18%;
      }
    }

    &.Rtable-cell--header {
      font-weight: 800;

      i {
        vertical-align: top;
        margin-right: 4px;
      }
    }
  }
}

.paddingNone {
  padding: 0;
}

// ad groups and users modal
.addUsersModal {
  .h6 {
    margin: 0;
  }

  .hr {
    margin: $spacer 0;
  }
}

.addNewUsersMeta {
  margin-top: $spacer * 0.5;
}

.addNewUsersNameInterpretation {
  font-size: $fontSizeLarger;
  font-weight: $fontWeightBold;
  margin-bottom: $spacer * 0.5;
}

.addNewUsersDescription {
  font-size: $fontSizeMedium;
  font-weight: $fontWeightLight;
}

.addNewUsersHeading {
  line-height: $lineHeightSmaller;

  &:first-child {
    margin: $spacer 0 $spacer * 0.5;
  }

  i {
    font-size: $fontSizeSmaller;
    vertical-align: 0;
  }
}

.addNewUsersContainer {
  margin-top: $spacer;

  @include forSmUp {
    margin-top: 0;
  }
}

.addNewUsersWrapper {
  margin: $spacer * 1.5 0;
  padding: 0 $spacer $spacer;
  background-color: $grayLightest;
  border: $borderBase $gray;
}

.addNewUsersCallout {
  color: $infoColor;
  font-size: 1em;
  font-style: italic;
  margin-top: $spacer * 0.75;

  i {
    vertical-align: -2px;
    margin-right: $spacer * 0.25;
  }
}

.addNewUsersAdded {
  margin: $spacer * 1.5 0 $spacer 0;
  border: $borderBase $tertiaryColor;
  padding: $spacer * 0.5 $spacer;
  background-color: rgba($successColor, 0.1);
}

.addNewUsersAddedList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.addNewUsersAddedListItem {
  display: flex;
  align-items: center;
  padding: $spacer * 0.5 0;

  .button {
    margin: $spacer * 0.125 0 0 $spacer * 0.75;
  }

  .checkbox {
    margin-left: $spacer * 0.875;
  }

  .checkLabel {
    font-size: $spacer * 0.875;
  }
}

.addNewUsersEmail {
  margin: 0 0 0 $spacer * 0.5;
}

.addNewUsersName {
  margin: 0;
}

.addNewUserIcon {
  font-size: 0.75em;
  margin: 0 $spacer * 0.5 0 0;
}

.addNewUsersInput {
  max-width: $spacer * 20;
  margin-top: $spacer * 1;
}

.externalUsersWrapper {
  padding: $spacer;
  margin: $spacer 0;
  background-color: $grayLightest;
  border: $borderBase $grayLight;
}

.exisitingUsersCount {
  border-bottom: $borderBase $grayLighter;
  padding-bottom: $spacer;

  i {
    vertical-align: -1px;
  }
}

.addNewUsersInputs {
  margin-bottom: $spacer;
}

.error {
  position: relative;
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;

  .h2 {
    color: $grayLighter;
    font-size: 150px; // px instead of rem to calc margin
    font-weight: 700;
    line-height: 0.75;
    margin: 0;
    position: relative;
    z-index: 1;
    text-align: center;

    @include forSmUp {
      font-size: 325px;
    }
  }

  .h3 {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    line-height: 1;
    font-size: 30px; // px instead of rem to calc margin
    font-weight: 300;
    text-transform: uppercase;

    @include forSmUp {
      font-size: 50px;
    }
  }
}

.errorCode {
  position: relative;
  margin-bottom: $spacer * 2;

  @include forSmUp {
    margin-bottom: $spacer * 3;
  }
}

.errorUnauthorized {
  .h3 {
    margin: -5px 0 0 -106px;

    @include forSmUp {
      margin: -5px 0 0 -175px;
    }
  }
}

.errorNotFound {
  .h3 {
    margin: -5px 0 0 -81px;

    @include forSmUp {
      margin: -5px 0 0 -135px;
    }
  }
}

.requestTypeModalSpacing {
  width: $spacer * 13;
  justify-content: space-between;

  .inputWrapper {
    width: $spacer * 3;
  }
}

// Kanban styles
.requestsKanban,
.requestsTabbar {
  margin-bottom: $spacer;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.kanbanBoard {
  @include kanbanBoard;
}

.kanbanHeading {
  @include kanbanHeading;
}

.kanbanHeadingTitle {
  font-size: $fontSizeSmall;
  margin: 0;
  &.New {
    color: $black;
  }
  &.Processing {
    color: $warningColor;
  }
  &.Completed {
    color: $successColor;
  }
  &.Archived {
    color: $gray;
  }
}

.kanbanHeadingValue {
  font-size: $fontSizeSmaller;
  margin: 0;
}

.kanbanColumn {
  @include kanbanColumn;
  @media only screen and (max-width: 1100px) {
    padding: $spacer;
  }
}

.kanbanCard {
  @include kanbanCard;
}

.kanbanCardTitle {
  @include kanbanCardTitle;
  &.New {
    background-color: $grayLightest;
  }
  &.Processing {
    background-color: lighten($warningColor, 40%);
  }
  &.Completed {
    background-color: lighten($successColor, 50%);
  }
  &.Archived {
    background-color: lighten($successColor, 50%);
  }
  &.High {
    background-color: $primaryColor;
    color: $white;
  }
}

.kanbanCardTitleText {
  flex-grow: 1;
  margin: 0;
}

.kanbanCardBody {
  @include kanbanCardBody;
}

.kanbanCardBodyIcons {
  display: flex;
  align-items: center;
  width: $spacer * 7;
  margin-top: $spacer - 0.5;

  i {
    margin-left: $spacer * 0.75;

    &:first-child {
      margin-left: 0;
    }
  }

  span {
    font-size: $fontSizeSmaller;
    margin-left: $spacer * 0.25;
  }
}

.kanbanCardRequestType {
  margin: $spacer * 0.25 0 0;
}

.kanbanCardBodyDate {
  font-size: $fontSizeSmaller;
  margin: $spacer * 0.125 0 0;

  &:empty {
    display: none;
  }
}

.kanbanCardModal {
  cursor: default;
  z-index: 1;
  display: inline-block;
}

.kanbanModalButton {
  font-size: $fontSizeLarger;
  line-height: 1;
  padding: 0;
  text-align: left;

  &:hover,
  &:focus {
    span {
      text-decoration: underline;
    }
  }
}

.kanbanModalButtonSummary {
  font-size: $fontSizeSmaller;
  margin-left: $spacer * 0.5;
  color: $grayDark;

  .kanbanModalButton:focus &,
  .kanbanModalButton:hover & {
    color: $grayDarkest;
  }
}

.kanbanCardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.kanbanCardAvatar {
  margin: 0;
  height: $spacer * 2.25;
  width: $spacer * 2.25;
  font-size: $fontSizeSmall;
}

.kanbanViewMoreButton {
  width: 100%;
  margin: $spacer 0;
}

.noDataKanban {
  position: absolute;
  height: $spacer * 6.7;
  width: 100%;
  top: $spacer * 4.6;
}

.kanbanFilters {
  @include flexAlignCenter;
  background-color: $primaryColorDark;
  padding: $spacer * 0.5 $spacer * 1;
  color: $white;
  margin-top: -$spacer;

  .search,
  .flexAlignCenter {
    margin-right: $spacer * 1;
  }

  .checkLabel,
  .labelText {
    font-size: $fontSizeSmall;
  }

  .label {
    font-weight: $fontWeightMedium;
  }
}

.requestTypeFilterLabel {
  margin: 0;
  margin-top: 0.75rem;
  // used a static value here because the fontsize wasn't consistent between the grid and kanban
  font-size: 0.75rem;
  font-weight: $fontWeightMedium;
  border-bottom: $borderBase $gray;
  color: $gray;
}

// Routes.tsx
.addEditRoute {
  display: inline-block;
}

.list {
  @include list;
}

.listItem {
  @include listItem;
}

.listItemBordered {
  @include listItemBordered;
}

.listItemStriped {
  @include listItemStriped;
}

.listItemStripedHover {
  @include listItemStripedHover;
}

.listItemInner {
  @include listItemInner;
}

// Toggle
.toggleButton {
  display: flex;
  align-items: center;
  z-index: 1;

  .react-toggle-track {
    width: $spacer * 3.8;
  }
  .react-toggle--checked .react-toggle-track {
    background-color: $successColor;
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: $successColorDark;
  }

  .react-toggle-thumb {
    left: 1px;
  }
  .react-toggle--focus .react-toggle-thumb,
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 38px;
    border-color: $successColor;
  }
}
.refreshGroup {
  margin-bottom: $spacer;
  @include flex;
  @media only screen and (min-width: 1230px) {
    margin-bottom: 0;
    position: absolute;
    right: $spacer * 1.25;
    top: 0.8rem;
  }
}

.refreshButton {
  font-size: $fontSizeMedium;
  .buttonIconLeft {
    margin-right: 0.5rem;
  }
  .refreshIcon,
  .refreshIconSpining {
    font-size: $fontSizeMedium;
  }

  .refreshIconSpinning {
    animation: spin 1.3s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.toggleLabelOn,
.toggleLabelOff {
  text-transform: uppercase;
  font-size: $fontSizeSmaller;
  color: $white;
  position: absolute;
  top: -3px;
  left: 5px;
}

.toggleLabelOff {
  position: absolute;
  top: -3px;
  right: 0;
  left: auto;
}

.toggleLabel {
  margin-left: $spacer * 0.5;
}

// swagger
.swagger {
  .block-desktop {
    width: 100%;
  }
}

// loginsActions
.loginsActions {
  .loginsActionsTable {
    @media (max-width: 1630px) and (min-width: 1024px) {
      overflow-x: scroll;
    }
  }
}
// recharts legend
.recharts-default-legend {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.recharts-legend-item {
  display: inline-flex !important;
  align-items: center;
}

.stat {
  h6 {
    margin: 0;
  }

  p {
    word-wrap: break-word;
  }
}

// requestHistory
.requestHistory {
  .tableWrapper {
    @include forMdUp {
      margin-top: $spacer;
      min-height: $spacer * 32.5;
      background-color: $grayLighter;
    }
  }

  .table {
    .td {
      font-size: $fontSizeSmall;
    }
    .alert {
      &:only-child {
        min-height: $spacer * 10;

        @include forMdUp {
          min-height: $spacer * 28;
        }
      }
    }
  }
}

.requestDetails {
  display: flex;
  flex-wrap: wrap;

  @include forSmUp {
    font-size: $fontSizeLarger;
  }

  .stat {
    margin-bottom: $spacer;
    padding-right: $spacer * 2;

    @include forSmUp {
      padding-right: $spacer * 3;
    }
  }

  + .hr {
    margin-top: 0;
  }
}

.requestAudits {
  .alert {
    margin: $spacer 0;
  }
}

.requestAuditListWrapper {
  button {
    margin-bottom: $spacer;
  }
}

.requestAuditsList {
  @include listNone;
  margin-top: $spacer;

  .requestDetail & {
    margin-top: $spacer;
    font-size: $fontSizeSmall;
  }
}

.requestAuditsListItem {
  @include listItem;
  @include listItemBordered;
  @include listItemStripedHover;
}

.requestAudit {
  @include forSmUp {
    display: flex;
    flex-wrap: wrap;
  }

  @include forXlUp {
    flex-wrap: nowrap;
  }

  .stat {
    margin-bottom: $spacer;
    min-height: $spacer * 2;
    padding-right: $spacer * 2;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      @include forSmUp {
        width: 33.333%;
      }

      @include forXlUp {
        flex-shrink: 0;
        width: 16.666%;
        max-width: $spacer * 11;
      }
    }

    &:nth-child(4),
    &:nth-child(5) {
      @include forSmUp {
        flex-shrink: 1;
        width: 100%;
      }
    }
  }
}

.requestDetailHistory {
  @include flexAlignCenter;
  position: absolute;
  right: 0.5rem;
  top: 5px;
  .requestDetailIcon {
    font-size: $fontSizeLargest;
  }

  .button.buttonLinkPrimaryColor {
    font-size: $fontSizeMedium;
    z-index: 1;
  }
}

.textEllipsisBlock {
  @include textEllipsis;
  display: block;
}

.textEllipsisInlineBlock {
  @include textEllipsis;
  display: inline-block;
}

.requestAdditionalUsers {
  @include listNone;

  .requestAdditionalUsersListItem {
    padding: $spacer;
    @include listItemStripedHover;
  }

  .removeButton {
    margin-left: $spacer;
  }
}

.requestAdditionalUsersSelect {
  width: $spacer * 15;

  .reactSelect__menu {
    margin-bottom: $spacer * 2;

    @include forSmUp {
      min-width: $spacer * 20;
    }
  }
}

// editor
.editorWrapper {
  border: $borderBase $gray;
}
.editorToolbar {
  position: relative;
  padding: 0.5rem 0;
  background-color: $grayLightest;
}
.editor {
  padding: $spacer;
}
.editorButton,
.editorButtonActive {
  border: none;
  cursor: pointer;
  outline: 0;
  opacity: 0.5;
  padding: 0 $spacer;
  background-color: transparent;
  &:hover {
    opacity: 1;
  }
  .editorIcon {
    vertical-align: text-bottom;
  }
}

.editorButtonActive {
  opacity: 1;
}

.editorImage {
  max-height: 100%;
  max-width: $spacer * 50;
}

.requestTypeSettings {
  .table {
    .td {
      font-size: $fontSizeSmall;
    }
  }
}

.requestTypeSettingsMetadata {
  .h2 {
    margin-bottom: $spacer;
  }

  p {
    font-size: $fontSizeSmall;
    font-style: italic;
  }
}

.requestDescription {
  background-color: $grayLightest;
  border: $borderBase $gray;
  color: $grayDark;
  padding: $spacer;

  + .alert {
    margin-top: $spacer;
  }
}

.reports {
  .sectionInner {
    min-height: $spacer * 20;
  }
}

.usageReportsControls {
  display: flex;
  flex-wrap: wrap;
}

.usageReportsDatePicker {
  min-width: $spacer * 18;
  margin-right: $spacer;

  .DateRangePickerInput__withBorder {
    border: $borderBase $gray;
    border-radius: 0;
  }

  .label {
    margin-bottom: $spacer;
  }
}

.usageReportsTimezonePicker {
  min-width: $spacer * 16;

  .selectLargest {
    font-weight: $fontWeightLight;
    height: $spacer * 3;
    padding: $spacer * 0.375 $spacer * 2.25 $spacer * 0.375 $spacer * 0.5;
  }
}

// app modes
.appModes {
  .checkLabel {
    font-size: 1.125em;
    font-weight: 600;
  }

  .h2 {
    margin-bottom: $spacer * 0.5;
  }

  &:not(.privacyPolicy) .hr {
    margin-top: 0;
  }

  .p {
    &:first-of-type {
      font-size: 0.875em;
      font-weight: 300;
      margin: $spacer * 0.5 0 0 $spacer * 1.5;
    }

    &:last-of-type {
      color: $gray;
      font-size: 0.75em;
      margin: $spacer * 0.5 0 0 $spacer * 1.5;
      text-transform: uppercase;
    }
  }

  .sectionInner {
    min-height: $spacer * 15;
  }
}

.dateRangePickerWithSearch {
  @include forSmUp {
    display: flex;
  }

  .dateRangePickerWrapper {
    @include forSmUp {
      height: $spacer * 2.25;
      max-width: 18.5rem;
      padding: $spacer * 0.25 $spacer * 0.125 0;
    }
  }

  .dateTimePickerDate {
    @include forSmUp {
      width: $spacer * 7;
    }
  }

  .dateTimePicker {
    @include forSmUp {
      font-size: 0.875em;
    }
  }

  .search {
    margin-top: $spacer;

    @include forSmUp {
      margin-left: $spacer;
      margin-top: 0;
    }
  }
}

.dateRangePickerWithSearchAuditWrapper {
  display: flex;
  flex-wrap: wrap;
}

.dateRangePickerWithSearchAudit {
  flex: 1;
  @include forSmUp {
    display: flex;
  }

  .dateRangePickerWrapper {
    @include forSmUp {
      height: $spacer * 2.25;
      max-width: 18.5rem;
      padding: $spacer * 0.25 $spacer * 0.125 0;
    }
  }

  .dateTimePickerDate {
    @include forSmUp {
      width: $spacer * 7;
    }
  }

  .dateTimePicker {
    @include forSmUp {
      font-size: 0.875em;
    }
  }

  .search {
    margin-top: $spacer;
    width: 100%;

    > .inputWrapper {
      max-width: 14rem;
    }

    @media (min-width: 1024px) {
      > .inputWrapper {
        max-width: 5rem;
      }
    }
    @media (min-width: 1084px) {
      > .inputWrapper {
        max-width: 9rem;
      }
    }
    @media (min-width: 1167px) {
      > .inputWrapper {
        max-width: 14rem;
      }
    }

    @include forSmUp {
      margin-left: $spacer;
      margin-top: 0;
    }
  }
}
.dateRangePickerWithSearchAuditAction {
  margin-top: $spacer;
  width: 100%;
  @include forMdUp {
    width: 10rem;
    margin-top: -0.75rem;
    margin-bottom: -1rem;
  }
}

.slaManagementButtonWrapper {
  margin-bottom: -$spacer;
  display: flex;
}

.slaManagementSelect {
  width: 100%;
  margin-bottom: $spacer;
  @include forSmUp {
    width: 24%;
    margin-bottom: 0;
  }
}

.slaManagementSelectWrapper {
  display: flex;
  flex-direction: column;
  @include forSmUp {
    margin-bottom: $spacer;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.slaManagementResetButton {
  padding: 0;
  margin-bottom: $spacer;
}

.slaUpdateModalWrapper {
  min-height: auto;
}
//WYSIWYG Editor.ql-container
.quill {
  .ql-snow {
    font-size: $spacer;
    .ql-editor {
      @include wysiwyg;
    }
  }
}

.editor-heading {
  &,
  .appModes & {
    margin-bottom: $spacer;
  }
}
