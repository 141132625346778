@mixin fadeIn {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin fadeOut {
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@mixin fadeInDown {
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0, -50%, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
}

@mixin fadeOutUp {
  @keyframes fadeOutUp {
    0% {
      opacity: 1;
      transform: none;
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -50%, 0);
    }
  }
}

@mixin fade {
  @include fadeIn;
  @include fadeOut;
  @include fadeInDown;
  @include fadeOutUp;
}
