@mixin tabbar {
  background-color: $primaryColor;
  width: 100%;
  display: flex;
  align-items: flex-end;
  color: $white;
  padding: 1px $spacer 0;
}

@mixin tabbarButton {
  font-size: $fontSizeSmaller;
  font-weight: $fontWeightBold;
  line-height: $lineHeightSmaller;
  justify-content: center;
  width: $spacer * 6;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  height: $spacer * 2.5;
  margin: 0 $spacer - 0.5;
  border: none;
  color: $white;
  border-bottom: $borderThicker transparent;
  &:focus {
    outline: 0;
  }
  :first-child {
    margin-right: $spacer - 0.5;
  }
  &:hover {
    border-bottom: 3px solid $white;
    transition: border 0.3s ease-in-out;
  }
}

@mixin tabbarButtonSelected {
  @include tabbarButton;
  border-bottom: 3px solid $white;
}
