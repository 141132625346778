@mixin forXxxxsUp {
  @media (min-width: $xxxxs) {
    @content;
  }
}

@mixin forXxxsUp {
  @media (min-width: $xxxs) {
    @content;
  }
}

@mixin forXxsUp {
  @media (min-width: $xxs) {
    @content;
  }
}

@mixin forXsUp {
  @media (min-width: $xs) {
    @content;
  }
}

@mixin forSmUp {
  @media (min-width: $sm) {
    @content;
  }
}

@mixin forMdUp {
  @media (min-width: $md) {
    @content;
  }
}

@mixin forLgUp {
  @media (min-width: $lg) {
    @content;
  }
}

@mixin forXlUp {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin forXxlUp {
  @media (min-width: $xxl) {
    @content;
  }
}

@mixin forXxxlUp {
  @media (min-width: $xxxl) {
    @content;
  }
}

@mixin forXxxxlUp {
  @media (min-width: $xxxxl) {
    @content;
  }
}
