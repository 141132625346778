@mixin footer {
  background-color: $grayLightest;
  color: $grayDark;
  font-size: $fontSizeSmaller;
  padding: $spacer * 0.75 $spacer * 0.75 $spacer;
  width: 100%;

  @include forMdUp {
    padding: $spacer * 1.25 $spacer * 1.25 $spacer * 1.75;
  }
}
