@mixin manageUsers {
  .form {
    background-color: transparent;
    border: 0;
    padding: 0;

    @include forXxxsUp {
      display: flex;
      align-items: flex-end;
      .field {
        margin-bottom: 0;
      }
      .button {
        margin-left: $spacer;
      }
    }
    @include forXxsUp {
      .input {
        min-width: $spacer * 20;
      }
    }
  }
  .input {
    border: 0;
    min-width: 0;
    padding: 0;
  }
  .label {
    &:first-child {
      margin-bottom: $spacer;
    }
    &:only-child {
      margin: 0;
    }
  }
}
