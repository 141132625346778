@mixin openMenu {
  @include buttonLinkGrayLight;
  margin-left: auto;
  
  @include forMdUp {
    display: none;
  }
}

@mixin closeMenu {
  @include button;
  background-color: lighten($grayDarkest, 5%);
  border-color: lighten($grayDarkest, 5%);
  border-left: $borderThick $grayDarkest;
  border-bottom: $borderThick $grayDarkest;
  color: $grayLight;
  display: none;
  height: $spacer * 3;
  padding-left: 0;
  text-align: right;
  width: 100%;

  &:visited {
    color: $grayLight;
  }
  &:hover,
  &:focus {
    color: $grayLighter;
    top: 0;
  }
  &:active {
    color: $grayLightest;
  }
  .menuOpen & {
    display: block;
    @include forMdUp {
      display: none;
    }
  }
}
