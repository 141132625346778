@mixin overlay {
  animation-name: fadeIn;
  animation-duration: 0.1s;
  animation-fill-mode: both;
  background-color: rgba($black, 0.7);
  cursor: pointer;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  .menuOpen & {
    display: block;
    @include forMdUp {
      display: none;
    }
  }

  .menuIsClosing & {
    animation-name: fadeOut;
    animation-delay: 0.3s;
    animation-duration: 0.1s;
    animation-fill-mode: both;
    background-color: rgba($black, 0.7);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
